import React, { useState, useEffect } from "react";

import { Avatar, Box, Tooltip, Checkbox, Alert } from "@mui/material";
import { Visibility, VisibilityOff, RequestQuote } from "@mui/icons-material";
import axios from "axios";
import apiUrl from "../api";
import PopUpWindow from "../popUpWindow/PopUpWindow";
import NewFundTransactionForm from "../newFundTransactionForm/NewFundTransactionForm";
import PrimaryButton from "../primaryButton/PrimaryButton";

import Loader from "../loader/Loader";
import ViewTransaction from "../viewTransaction/ViewTransaction";
import TransactionTable from "../transactionTable/TransactionTable";
import io from "socket.io-client";

function formatDate(date) {
  date = new Date(date);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
}

const getColor = (name) => {
  let hash = 0;
  for (let i = 0; i < name.length; i++) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }
  const color = `hsl(${hash % 360}, 100%, 30%)`;
  return color;
};

const socket = io(`${apiUrl}/`);

const UserTransactions = (props) => {
  const [user, setUser] = useState();
  const userId = props.userId;

  const me = JSON.parse(localStorage.getItem("user"));

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [timelineItems, setTimelineItems] = useState([]);
  const [timelineTotal, setTimelineTotal] = useState(0);
  const [timelinePage, setTimelinePage] = useState(1);

  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [selectedTimeline, setSelectedTimeline] = useState(null);
  const [transactionIsLoading, setTransactionIsLoading] = useState(false);
  const [transactionPopupOpen, setTransactionPopupOpen] = useState(false);

  const fetchTransaction = async (transactionId) => {
    try {
      setTransactionIsLoading(true);
      const response = await axios.get(
        `${apiUrl}/transaction/${transactionId}`,
        {
          headers: {
            Authorization: `Bearer ${me.token}`,
          },
        }
      );

      const transaction = response.data.transaction;
      setSelectedTransaction({
        _id: transaction._id,
        date: formatDate(transaction.transaction_date),
        amount: transaction.amount,
        currency: transaction.currency,
        reason: transaction.reason,
        payment_method: transaction.payment_method,
        status: transaction.transaction_status,
        receiver: transaction.is_receiver
          ? transaction.user
          : transaction.company
          ? transaction.company
          : "",
        sender: transaction.is_receiver
          ? transaction.company
            ? transaction.company
            : transaction.is_payment
            ? transaction.user
            : ""
          : transaction.user,
        property: transaction.property,
        notes: transaction.notes,
        is_payment: transaction.is_payment,
        attachments: transaction.attachments,
        created_at: transaction.created_at,
        updated_at: transaction.updated_at,
        approved_by: transaction.approved_by,
        rejected_by: transaction.rejected_by,
        approved_at: transaction.approved_at,
        rejected_at: transaction.rejected_at,
      });

      setTransactionIsLoading(false);
    } catch (error) {
      setError("Error fetching transaction");
    }
  };

  useEffect(() => {
    const fetchUser = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(
          `${apiUrl}/auth/getUserFullInfo/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${me.token}`,
            },
          }
        );
        setUser(response.data.user);
        console.log(response.data.user);
        setIsLoading(false);
      } catch (error) {
        setError("Error fetching user");
      }
    };
    fetchUser();
  }, []);

  useEffect(() => {
    const fetchTimeline = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(
          `${apiUrl}/timeline/getUserTimelines/${userId}?page=${timelinePage}`,
          {
            headers: {
              Authorization: `Bearer ${me.token}`,
            },
          }
        );
        setTimelineItems((prevItems) => [
          ...prevItems,
          ...response.data.timelines,
        ]);
        setTimelineTotal(response.data.totalItems);
        setIsLoading(false);
      } catch (error) {
        setError("Error fetching timeline");
      }
    };
    fetchTimeline();
  }, [timelinePage]);

  useEffect(() => {
    const handleUpdateUserBalance = (data) => {
      if (data.user_id === userId) {
        setUser((prevUser) => {
          return { ...prevUser, balance: data.new_balance };
        });
        
      }
    };

    socket.on("updateUserBalance", handleUpdateUserBalance);

    return () => {
      socket.off("updateUserBalance", handleUpdateUserBalance);
    };
  }, [userId]);

  return (
    <div
      className="profile__container"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
      }}
    >
      {transactionPopupOpen && (
        <PopUpWindow
          onClose={() => setTransactionPopupOpen(false)}
          unfocusClose={true}
        >
          {transactionIsLoading ? (
            <Loader />
          ) : (
            <ViewTransaction transaction={selectedTransaction} />
          )}
        </PopUpWindow>
      )}
      {!isLoading && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <PrimaryButton
              text={"New Fund Transaction"}
              icon={<RequestQuote />}
            >
              <NewFundTransactionForm partner={user} />
            </PrimaryButton>
          </div>
          <div
            className="profile__balance"
            style={{
              color: user.balance <= 0 ? "green" : "red",
              display: "flex",
            }}
          >
            <h2>Balance: {user.balance}€</h2>
          </div>

          <div className="profile__avatar">
            <Avatar
              style={{
                backgroundColor: getColor(user.name),
                width: "50px",
                height: "50px",
                fontSize: "25px",
              }}
            >
              {user.name.charAt(0).toUpperCase()}
            </Avatar>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <TransactionTable user={user._id} />
          </div>

          <div className="profile__info">
            <div className="form-group">
              <div className="form-field">
                <label className="form-field-label" htmlFor="fullName">
                  Full Name:
                </label>
                <input
                  className="form-field-input"
                  type="text"
                  id="fullName"
                  name="fullName"
                  value={user.name}
                  autoComplete="off" // Added here
                  onChange={(e) => {
                    setUser({ ...user, name: e.target.value });
                    console.log(user.name);
                  }}
                  readOnly
                />
              </div>

              <div className="form-field">
                <label className="form-field-label" htmlFor="username">
                  Username:
                </label>

                <input
                  className="form-field-input"
                  type="text"
                  id="username"
                  name="username"
                  value={user.username}
                  autoComplete="off" // Added here
                  onChange={(e) =>
                    setUser({ ...user, username: e.target.value })
                  }
                  readOnly
                />
              </div>
            </div>
            <div className="form-group">
              <div className="form-field">
                <label className="form-field-label" htmlFor="email">
                  Email:
                </label>
                <input
                  className="form-field-input"
                  type="email"
                  id="email"
                  name="email"
                  value={user.email}
                  autoComplete="off" // Added here
                  onChange={(e) => setUser({ ...user, email: e.target.value })}
                  readOnly
                />
              </div>

              <div className="form-field">
                <label className="form-field-label" htmlFor="phone">
                  Phone:
                </label>
                <input
                  className="form-field-input"
                  type="text"
                  id="phone"
                  name="phone"
                  value={user.phone}
                  autoComplete="off" // Added here
                  onChange={(e) => setUser({ ...user, phone: e.target.value })}
                  readOnly
                />
              </div>
            </div>

            <div className="form-group">
              <div className="form-field">
                <label className="form-field-label" htmlFor="taxId">
                  Tax ID:
                </label>
                <input
                  className="form-field-input"
                  type="text"
                  id="taxId"
                  name="taxId"
                  value={user.tax_id}
                  autoComplete="off" // Added here
                  onChange={(e) => setUser({ ...user, tax_id: e.target.value })}
                  readOnly
                />
              </div>

              <div className="form-field">
                <label className="form-field-label" htmlFor="amka">
                  AMKA:
                </label>
                <input
                  className="form-field-input"
                  type="text"
                  id="amka"
                  name="amka"
                  value={user.amka}
                  autoComplete="off" // Added here
                  onChange={(e) => setUser({ ...user, amka: e.target.value })}
                  readOnly
                />
              </div>

              <div className="form-field">
                <label className="form-field-label" htmlFor="iban">
                  IBAN:
                </label>
                <input
                  className="form-field-input"
                  type="text"
                  id="iban"
                  name="iban"
                  autoComplete="off" // Already present
                  value={user.iban}
                  onChange={(e) => setUser({ ...user, iban: e.target.value })}
                  readOnly
                />
              </div>
            </div>

            <div
              className="timeline"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: "90%",
                marginTop: "20px",
              }}
            >
              <h2>Timeline</h2>
              <div
                className="timeline-items"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  alignContent: "center",
                  width: "100%",
                  overflowY: "auto",
                  backgroundColor: "#fff",
                  padding: "10px",
                  borderRadius: "5px",
                }}
              >
                {timelineItems.map((item) => (
                  <div
                    key={item._id}
                    className="timeline-item"
                    onClick={async () => {
                      setSelectedTransaction(null);
                      await fetchTransaction(item.type_id);
                      setTransactionPopupOpen(true);
                    }}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      width: "100%",
                      padding: "10px",
                      margin: "5px",
                      borderRadius: "5px",
                      backgroundColor: "var(--tertiary-color)",
                      cursor: "pointer",
                    }}
                  >
                    <p>
                      {item.to_user === user._id
                        ? item.to_message
                        : item.from_message}
                    </p>
                    <p
                      style={{
                        fontSize: "12px",
                        color: "var(--secondary-text-color)",
                      }}
                    >
                      {new Date(item.created_at).toLocaleDateString()} -{" "}
                      {new Date(item.created_at).toLocaleTimeString()}
                    </p>
                  </div>
                ))}
              </div>
              {timelineItems.length < timelineTotal && (
                <button
                  onClick={() => setTimelinePage(timelinePage + 1)}
                  style={{
                    backgroundColor: "var(--primary-color)",
                    color: "var(--primary-text-color)",
                    fontSize: "15px",
                    fontWeight: "600",
                    width: "50%",
                  }}
                >
                  Load more
                </button>
              )}
            </div>
          </div>

          {error && <Alert severity="error">{error}</Alert>}
          {success && <Alert severity="success">{success}</Alert>}
        </div>
      )}

      {isLoading && <Loader />}
    </div>
  );
};

export default UserTransactions;
