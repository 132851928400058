import React, { useState, useEffect } from "react";
import { useUser } from "../../contexts/UserContext";
import { Email, CurrencyExchange } from "@mui/icons-material";
import { NewTransactionForm, PopUpWindow } from "..";

const PrimaryButton = ({
  children,
  icon,
  color,
  textColor,
  fontSize,
  fontWeight,
  width,
  text,
  onClick,
  borderRadius,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [buttonWidth, setButtonWidth] = useState(
    window.innerWidth <= 768 ? "100%" : "auto"
  );

  const [popUpWindowIsOpen, setPopUpWindowIsOpen] = useState(false);

  const handleClick = () => {
    onClick ? onClick() : setPopUpWindowIsOpen(true);
  };

  useEffect(() => {
    const handleResize = () => {
      const newWidth = window.innerWidth <= 768 ? "100%" : "auto";
      setButtonWidth(newWidth);
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {popUpWindowIsOpen && (
        <PopUpWindow
          onClose={() => {
            setPopUpWindowIsOpen(false);
          }}
          unfocusClose={true}
        >
          {children}
        </PopUpWindow>
      )}
      <div
        className="add-ticket-button"
      >
        <button
          type="button"
          className="add-ticket-button__button"
          style={{
            backgroundColor: color || "var(--primary-color)",
            color: textColor || "var(--primary-text-color)",
            fontSize: fontSize || "15px",
            fontWeight: fontWeight || "600",
            borderRadius: borderRadius || "50px",
            width: width || buttonWidth,
          }}
          onClick={handleClick}
        >
          {icon ? (
            icon
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "25px",
              }}
            />
          )}
          <span>{text}</span>
        </button>
      </div>
    </>
  );
};
export default PrimaryButton;
