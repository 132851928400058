import React, { useState, useEffect } from "react";

import axios from "axios";
import apiUrl from "../api";

const AddCompanyForm = (props) => {
  const [companyName, setCompanyName] = useState(
    props.company ? props.company.name : ""
  );
  const [companyTaxId, setCompanyTaxId] = useState(
    props.company ? props.company.taxId : ""
  );
  const [companyBalance, setCompanyBalance] = useState(
    props.company ? props.company.balance : 0
  );
  const [companyBank, setCompanyBank] = useState(
    props.company ? props.company.bank : ""
  );
  const [companyIBAN, setCompanyIBAN] = useState(
    props.company ? props.company.iban : ""
  );
  const [companySwift, setCompanySwift] = useState(
    props.company ? props.company.swiftBic : ""
  );

  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const user = JSON.parse(localStorage.getItem("user"));

  const handleCreateCompany = async (e) => {
    e.preventDefault();
    if (
      !companyName ||
      !companyTaxId ||
      !companyBalance ||
      !companyBank ||
      !companyIBAN ||
      !companySwift
    ) {
      setErrorMessage("All fields are required!");
    } else {
      try {
        const response = await axios.post(
          `${apiUrl}/company`,
          {
            name: companyName,
            taxId: companyTaxId,
            balance: companyBalance,
            bank: companyBank,
            iban: companyIBAN,
            swiftBic: companySwift,
          },
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        );
        setSuccessMessage("Company created successfully!");
        setErrorMessage("");
        setCompanyName("");
        setCompanyTaxId("");
        setCompanyBalance("");
        setCompanyBank("");
        setCompanyIBAN("");
        setCompanySwift("");
      } catch (error) {
        setErrorMessage(error.response.data.message);
        setSuccessMessage("");
      }
    }
  };

  const handleUpdateCompany = async (e) => {
    e.preventDefault();
    if (
      !companyName ||
      !companyTaxId ||
      !companyBalance ||
      !companyBank ||
      !companyIBAN ||
      !companySwift
    ) {
      setErrorMessage("All fields are required!");
    } else {
      try {
        const response = await axios.put(
          `${apiUrl}/company/${props.company._id}`,
          {
            name: companyName,
            taxId: companyTaxId,
            balance: companyBalance,
            bank: companyBank,
            iban: companyIBAN,
            swiftBic: companySwift,
          },
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        );
        setSuccessMessage("Company updated successfully!");
        setErrorMessage("");
      } catch (error) {
        setErrorMessage(error.response.data.message);
        setSuccessMessage("");
      }
    }
  };

  return (
    <div className="add-ticket-form-wrapper" style={{}}>
      <form
        className="ticket-form"
        onSubmit={props.edit ? handleUpdateCompany : handleCreateCompany}
        style={{
          width: "100%",
        }}
      >
        <div className="form-group">
          <div className="form-field">
            <label className="form-field-label">Company Name</label>
            <input
              className="form-field-input"
              type="text"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
            />
          </div>
          <div className="form-field">
            <label className="form-field-label">Company Tax ID</label>
            <input
              className="form-field-input"
              type="text"
              value={companyTaxId}
              onChange={(e) => setCompanyTaxId(e.target.value)}
            />
          </div>
        </div>
        <div className="form-group">
          <div className="form-field">
            <label className="form-field-label">Company Balance</label>
            <input
              className="form-field-input"
              type="number"
              value={companyBalance}
              step={0.01}
              onChange={(e) => setCompanyBalance(e.target.value)}
            />
          </div>
          <div className="form-field">
            <label className="form-field-label">Company Bank</label>
            <input
              className="form-field-input"
              type="text"
              value={companyBank}
              onChange={(e) => setCompanyBank(e.target.value)}
            />
          </div>
        </div>
        <div className="form-group">
          <div className="form-field">
            <label className="form-field-label">Company IBAN</label>
            <input
              className="form-field-input"
              type="text"
              value={companyIBAN}
              onChange={(e) => setCompanyIBAN(e.target.value)}
            />
          </div>
          <div className="form-field">
            <label className="form-field-label">Company Swift</label>
            <input
              className="form-field-input"
              type="text"
              value={companySwift}
              onChange={(e) => setCompanySwift(e.target.value)}
            />
          </div>
        </div>
        <div className="form-group">
          <div className="form-field">
            <button
              type="submit"
              className="add-ticket-button__button"
              style={{
                backgroundColor: props.color || "var(--primary-color)",
                color: props.textColor || "var(--primary-text-color)",
                fontSize: props.fontSize || "15px",
                fontWeight: props.fontWeight || "600",
                width: props.width || "100%",
              }}
            >
              <span>{props.edit ? "Update" : "Add"}</span>
            </button>
          </div>
        </div>
      </form>
      {errorMessage && (
        <div className="error-message" style={{ color: "red" }}>
          {errorMessage}
        </div>
      )}
      {successMessage && (
        <div className="success-message" style={{ color: "green" }}>
          {successMessage}
        </div>
      )}
    </div>
  );
};

export default AddCompanyForm;
