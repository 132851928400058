import React, { useEffect } from "react";
import { useLocation } from "react-router-dom"; // Import useLocation
import {
  AddTicketButton,
  NewFundTransactionForm,
  PrimaryButton,
  Sidebar,
  TaskManager,
  TicketTable,
  TransactionTable,
  UserTransactions as UserTransactionsComponent,
} from "../../components";

import { RequestQuote } from "@mui/icons-material";
import { PageStructure } from "..";

const UserTransactions = (props) => {
  // This hook returns the current location object
  const location = useLocation();

  useEffect(() => {
    // This effect will run whenever the location (URL) changes
    // Place your logic here to fetch new data based on the new URL
    // For example, parsing query strings from the URL and fetching data accordingly
  }, [location]); // Depend on location to trigger effect on URL change

  return (
    <PageStructure>
      <h1>Users Transactions</h1>

     
        <UserTransactionsComponent userId={props.userId} />
    </PageStructure>
  );
};

export default UserTransactions;
