import React, { useState, useEffect } from "react";
import { Tooltip } from "@mui/material";
import Select from "react-select";
import { Loader, PrimaryButton } from "../../components";
import apiUrl from "../../components/api";
import axios from "axios";

const AddOwner = (props) => {
  const me = JSON.parse(localStorage.getItem("user"));

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [loading, setLoading] = useState(true);

  const [propertiesOptions, setPropertiesOptions] = useState([]);

  const [username, setUsername] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [tax_id, setTaxId] = useState("");
  const [amka, setAmka] = useState("");
  const [iban, setIban] = useState("");
  const [companies, setCompanies] = useState([]);
  const [properties, setProperties] = useState([]);

  const [selectedProperties, setSelectedProperties] = useState([]);

  const checkCompany = (company) => {
    if (company.name === "" 
        || company.taxId === ""

    ) {
      return false;
    }
    return true;
  };
  const makeClickable = (text) => {
    // Regex patterns for detecting URLs, emails, and phone numbers
    const urlPattern = /(https?:\/\/[^\s]+)/g;
    const emailPattern = /(\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b)/gi;
    const phonePattern = /(\b\d{3}[-.]?\d{3}[-.]?\d{4}\b)/g;

    // Replace URLs with anchor tags
    text = text.replace(
      urlPattern,
      (url) => `<a href="${url}" target="_blank">${url}</a>`
    );

    // Replace emails with mailto links
    text = text.replace(
      emailPattern,
      (email) => `<a href="mailto:${email}">${email}</a>`
    );

    // Replace phone numbers with tel links
    text = text.replace(
      phonePattern,
      (phone) => `<a href="tel:${phone}">${phone}</a>`
    );

    return text;
  };

  const handleSubmit = async (e) => {
    setErrorMessage("");
    setSuccessMessage("");
    e.preventDefault();
    if (username === "" || name === "" || email === "") {
      setSuccessMessage("");
      setErrorMessage("Please fill in all the required fields.");
      return;
    }

    if (selectedProperties.length === 0) {
      setSuccessMessage("");
      setErrorMessage("Please add at least one property.");
      return;
    }

    if (companies.length !== 0) {
      for (let i = 0; i < companies.length; i++) {
        if (!checkCompany(companies[i])) {
          setSuccessMessage("");
          setErrorMessage(
            "Please fill in all the required fields for the companies."
          );
          return;
        }
      }
    }
    const uploadedCompanies = [];

    try {
      for (const company of companies) {
        const response = await axios.post(
          `${apiUrl}/company-user/create`,
          {
            name: company.name,
            bank: company.bank,
            taxId: company.taxId,
            iban: company.iban,
            swiftBic: company.swiftBic,
            beneficiary: company.beneficiary,
            notifyId: company.notifyId,
            notes: company.notes,
          },
          {
            headers: {
              Authorization: `Bearer ${me.token}`,
            },
          }
        );
        uploadedCompanies.push(response.data.company._id);
      }

      if (props.edit) {
        const response = await axios.put(
          `${apiUrl}/auth/owner/${props.user}`,

          {
            username,
            name,
            email,
            phone,
            tax_id,
            amka,
            iban,
            properties: selectedProperties,
            companies: uploadedCompanies,
          },
          {
            headers: {
              Authorization: `Bearer ${me.token}`,
            },
          }
        );
      } else {
        const response = await axios.post(
          `${apiUrl}/auth/owner`,

          {
            username,
            name,
            email,
            phone,
            tax_id,
            amka,
            iban,
            properties: selectedProperties,
            companies: uploadedCompanies,
          },
          {
            headers: {
              Authorization: `Bearer ${me.token}`,
            },
          }
        );
      }

      setErrorMessage("");
      setSuccessMessage(props.edit ? "Owner updated." : "Owner added.");
    } catch (error) {
      setSuccessMessage("");
      setErrorMessage(error.response.data.message);
    }
  };

  useEffect(() => {
    const fetchProperties = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/property/getPropertiesNames`,
          {
            headers: {
              Authorization: `Bearer ${me.token}`,
            },
          }
        );
        setPropertiesOptions(
          response.data.propertiesNames.map((property) => property)
        );
      } catch (error) {
        console.log(error);
      }
    };

    const fetchUser = async () => {
      try {
        const response = await axios.get(`${apiUrl}/auth/owner/${props.user}`, {
          headers: {
            Authorization: `Bearer ${me.token}`,
          },
        });
        setUsername(response.data.owner.username);
        setName(response.data.owner.name);
        setEmail(response.data.owner.email);
        setPhone(response.data.owner.phone);
        setTaxId(response.data.owner.tax_id);
        setAmka(response.data.owner.amka);
        setIban(response.data.owner.iban);
        setSelectedProperties(response.data.owner.properties);
        setCompanies(response.data.owner.companies);
        setSelectedProperties(response.data.owner.properties);
      } catch (error) {
        console.log(error);
      }
    };

    fetchProperties();
    if (props.user) {
      fetchUser();
    }
  }, []);

  return (
    <div
      className="add-ticket-form-wrapper"
      style={{
        width: "100%",
      }}
    >
      <form
        className="ticket-form"
        onSubmit={handleSubmit}
        style={{
          width: "100%",
        }}
      >
        <div className="form-group">
          <div className="form-field">
            <label className="form-field-label">Full Name*: </label>
            {props.view ? (
              <div className="property-info-description">
                <p
                  dangerouslySetInnerHTML={{
                    __html: makeClickable(name),
                  }}
                ></p>
              </div>
            ) : (
              <input
                className="form-field-input"
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            )}
          </div>
          <div className="form-field">
            <label className="form-field-label">Username*:</label>
            {props.view ? (
              <div className="property-info-description">
                <p
                  dangerouslySetInnerHTML={{
                    __html: makeClickable(username),
                  }}
                ></p>
              </div>
            ) : (
              <input
                className="form-field-input"
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            )}
          </div>
        </div>

        <div className="form-group">
          <div className="form-field">
            <label className="form-field-label">Email*: </label>
            {props.view ? (
              <div className="property-info-description">
                <p
                  dangerouslySetInnerHTML={{
                    __html: makeClickable(email),
                  }}
                ></p>
              </div>
            ) : (
              <input
                className="form-field-input"
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            )}
          </div>
          <div className="form-field">
            <label className="form-field-label">Phone: </label>
            {props.view ? (
              <div className="property-info-description">
                <p
                  dangerouslySetInnerHTML={{
                    __html: makeClickable(phone),
                  }}
                ></p>
              </div>
            ) : (
              <input
                className="form-field-input"
                type="text"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            )}
          </div>
        </div>

        <div className="form-group">
          <div className="form-field">
            <label className="form-field-label">Tax ID: </label>
            {props.view ? (
              <div className="property-info-description">
                <p
                  dangerouslySetInnerHTML={{
                    __html: makeClickable(tax_id),
                  }}
                ></p>
              </div>
            ) : (
              <input
                className="form-field-input"
                type="text"
                value={tax_id}
                onChange={(e) => setTaxId(e.target.value)}
              />
            )}
          </div>
          <div className="form-field">
            <label className="form-field-label">AMKA: </label>
            {props.view ? (
              <div className="property-info-description">
                <p
                  dangerouslySetInnerHTML={{
                    __html: makeClickable(amka),
                  }}
                ></p>
              </div>
            ) : (
              <input
                className="form-field-input"
                type="text"
                value={amka}
                onChange={(e) => setAmka(e.target.value)}
              />
            )}
          </div>

          <div className="form-field">
            <label className="form-field-label">IBAN: </label>
            {props.view ? (
              <div className="property-info-description">
                <p
                  dangerouslySetInnerHTML={{
                    __html: makeClickable(iban),
                  }}
                ></p>
              </div>
            ) : (
              <input
                className="form-field-input"
                type="text"
                value={iban}
                onChange={(e) => setIban(e.target.value)}
              />
            )}
          </div>
        </div>

        <div className="form-group">
          <div className="form-field">
            <label className="form-field-label">Properties*: </label>
            <Select
              value={selectedProperties.map((property) => ({
                value: property,
                label: property.name,
              }))}
              isMulti
              options={propertiesOptions.map((property) => ({
                value: property,
                label: property.name,
              }))}
              onChange={(selectedOptions) => {
                setSelectedProperties(
                  selectedOptions.map((option) => option.value)
                );
              }}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: "var(--secondary-color)",
                  primary25: "var(--tertiary-color)",
                },
              })}
              isDisabled={props.view}
            />
          </div>
        </div>

        <div
          className="form-group"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="form-field">
            <label className="form-field-label">Companies: </label>
            <div
              className="divider"
              style={{
                width: "100%",
                height: "3px",
                backgroundColor: "var(--secondary-color)",
                padding: "0",
                margin: "10px",
              }}
            />
            {companies.map((company, index) => (
              <>
                <div key={index} className="form-group">
                  <div className="form-field">
                    <label className="form-field-label">Name*: </label>
                    {props.view ? (
                      <div className="property-info-description">
                        <p
                          dangerouslySetInnerHTML={{
                            __html: makeClickable(company.name),
                          }}
                        ></p>
                      </div>
                    ) : (
                      <input
                        className="form-field-input"
                        type="text"
                        value={company.name}
                        onChange={(e) => {
                          const newCompanies = [...companies];
                          newCompanies[index].name = e.target.value;
                          setCompanies(newCompanies);
                        }}
                      />
                    )}
                  </div>
                </div>
                <div key={index} className="form-group">
                  <div className="form-field">
                    <label className="form-field-label">Tax ID*: </label>
                    {props.view ? (
                      <div className="property-info-description">
                        <p
                          dangerouslySetInnerHTML={{
                            __html: makeClickable(company.taxId),
                          }}
                        ></p>
                      </div>
                    ) : (
                      <input
                        className="form-field-input"
                        type="text"
                        value={company.taxId}
                        onChange={(e) => {
                          const newCompanies = [...companies];
                          newCompanies[index].taxId = e.target.value;
                          setCompanies(newCompanies);
                        }}
                      />
                    )}
                  </div>

                  <div className="form-field">
                    <label className="form-field-label">IBAN: </label>
                    {props.view ? (
                      <div className="property-info-description">
                        <p
                          dangerouslySetInnerHTML={{
                            __html: makeClickable(company.iban),
                          }}
                        ></p>
                      </div>
                    ) : (
                      <input
                        className="form-field-input"
                        type="text"
                        value={company.iban}
                        onChange={(e) => {
                          const newCompanies = [...companies];
                          newCompanies[index].iban = e.target.value;
                          setCompanies(newCompanies);
                        }}
                      />
                    )}
                  </div>
                  <div className="form-field">
                    <label className="form-field-label">Bank: </label>
                    {props.view ? (
                      <div className="property-info-description">
                        <p
                          dangerouslySetInnerHTML={{
                            __html: makeClickable(company.bank),
                          }}
                        ></p>
                      </div>
                    ) : (
                      <input
                        className="form-field-input"
                        type="text"
                        value={company.bank}
                        onChange={(e) => {
                          const newCompanies = [...companies];
                          newCompanies[index].bank = e.target.value;
                          setCompanies(newCompanies);
                        }}
                      />
                    )}
                  </div>
                </div>

                <div key={index} className="form-group">
                  <div className="form-field">
                    <label className="form-field-label">SWIFT/BIC: </label>
                    {props.view ? (
                      <div className="property-info-description">
                        <p
                          dangerouslySetInnerHTML={{
                            __html: makeClickable(company.swiftBic),
                          }}
                        ></p>
                      </div>
                    ) : (
                      <input
                        className="form-field-input"
                        type="text"
                        value={company.swiftBic}
                        onChange={(e) => {
                          const newCompanies = [...companies];
                          newCompanies[index].swiftBic = e.target.value;
                          setCompanies(newCompanies);
                        }}
                      />
                    )}
                  </div>

                  <div className="form-field">
                    <label className="form-field-label">Beneficiary: </label>
                    {props.view ? (
                      <div className="property-info-description">
                        <p
                          dangerouslySetInnerHTML={{
                            __html: makeClickable(company.beneficiary),
                          }}
                        ></p>
                      </div>
                    ) : (
                      <input
                        className="form-field-input"
                        type="text"
                        value={company.beneficiary}
                        onChange={(e) => {
                          const newCompanies = [...companies];
                          newCompanies[index].beneficiary = e.target.value;
                          setCompanies(newCompanies);
                        }}
                      />
                    )}
                  </div>

                  <div className="form-field">
                    <label className="form-field-label">Notify ID: </label>
                    {props.view ? (
                      <div className="property-info-description">
                        <p
                          dangerouslySetInnerHTML={{
                            __html: makeClickable(company.notifyId),
                          }}
                        ></p>
                      </div>
                    ) : (
                      <input
                        className="form-field-input"
                        type="text"
                        value={company.notifyId}
                        onChange={(e) => {
                          const newCompanies = [...companies];
                          newCompanies[index].notifyId = e.target.value;
                          setCompanies(newCompanies);
                        }}
                      />
                    )}
                  </div>
                </div>

                <div key={index} className="form-group">
                  <div className="form-field">
                    <label className="form-field-label">Notes: </label>
                    {props.view ? (
                      <div className="property-info-description">
                        <p
                          dangerouslySetInnerHTML={{
                            __html: makeClickable(company.notes),
                          }}
                        ></p>
                      </div>
                    ) : (
                      <textarea
                        className="form-field-input"
                        value={company.notes}
                        onChange={(e) => {
                          const newCompanies = [...companies];
                          newCompanies[index].notes = e.target.value;
                          setCompanies(newCompanies);
                        }}
                      />
                    )}
                  </div>
                </div>

                <div
                  style={{
                    display: props.view ? "none" : "flex",
                  }}
                >
                  <PrimaryButton
                    text="Delete Company"
                    color="#BA0404FF"
                    onClick={() => {
                      const newCompanies = [...companies];
                      newCompanies.splice(index, 1);
                      setCompanies(newCompanies);
                    }}
                    style={{
                      padding: "5px",
                      margin: "5px",
                    }}
                  />
                </div>
                <div
                  className="divider"
                  style={{
                    width: "100%",
                    height: "3px",
                    backgroundColor: "var(--secondary-color)",
                    padding: "0",
                    margin: "10px",
                  }}
                />
              </>
            ))}

            <div
              style={{
                display: props.view ? "none" : "flex",

                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <PrimaryButton
                text="Add More Companies"
                color="var(--secondary-color)"
                onClick={() => {
                  setCompanies([
                    ...companies,
                    {
                      name: "",
                      taxId: "",
                      iban: "",
                      bank: "",
                      swiftBic: "",
                      beneficiary: "",
                      notifyId: "",
                      notes: "",
                    },
                  ]);
                }}
                style={{
                  padding: "5px",
                  margin: "5px",
                }}
              />
            </div>
          </div>
        </div>

        <div className="form-group">
          <div className="form-field">
            <button
              type="submit"
              className="add-ticket-button__button"
              style={{
                display: props.view ? "none" : "block",
                backgroundColor: props.color || "var(--primary-color)",
                color: props.textColor || "var(--primary-text-color)",
                fontSize: props.fontSize || "15px",
                fontWeight: props.fontWeight || "600",
                width: props.width || "100%",
              }}
            >
              {props.edit ? "Update Owner" : "Add Owner"}
            </button>
          </div>
        </div>
      </form>
      {errorMessage && (
        <div className="error-message" style={{ color: "red" }}>
          {errorMessage}
        </div>
      )}
      {successMessage && (
        <div className="success-message" style={{ color: "green" }}>
          {successMessage}
        </div>
      )}
    </div>
  );
};

export default AddOwner;
