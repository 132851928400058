import React, { useState, useEffect } from "react";
import Select from "react-select";
import axios from "axios";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { EditorState, RichUtils, convertToRaw, convertFromRaw } from "draft-js";
import { Delete } from "@mui/icons-material";
import { Tooltip, Alert, TextField } from "@mui/material";
import { FileUpload } from "@mui/icons-material";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import apiUrl from "../api";
import io from "socket.io-client";

import { ContentState, convertFromHTML } from "draft-js";

import "./NewFundTransactionForm.css";
import PrimaryButton from "../primaryButton/PrimaryButton";
import DraftEditor from "../Editor/DraftEditor";

function formatDateData(date) {
  date = new Date(date);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${year}-${month}-${day}`;
}

function formatAmount(amount) {
  return amount.toFixed(2);
}

const socket = io(`${apiUrl}/`);

const NewFundTransactionForm = (props) => {
  const [selectedTransaction, setSelectedTransaction] = useState(
    props.selectedTransaction
  );

  const [isLoading, setIsLoading] = useState(false);

  const [companies, setCompanies] = useState([]);

  const currencyOptions = [
    {
      value: "EUR",
      label: "€",
    },
    {
      value: "USD",
      label: "$",
    },
    {
      value: "GBP",
      label: "£",
    },
    {
      value: "JPY",
      label: "¥",
    },
    {
      value: "RUB",
      label: "₽",
    },
  ];

  const [paymentMethods, setPaymentMethods] = useState([]);

  const [transactionReasons, setTransactionReasons] = useState([]);
  const [properties, setProperties] = useState([]);
  const [reasons, setReasons] = useState([]);
  const [methodsOfPayment, setMethodsOfPayment] = useState([]);
  const [partners, setPartners] = useState([]);
  // Convert HTML string to EditorState
  const contentBlock = htmlToDraft(
    selectedTransaction ? selectedTransaction.notes : "<p></p>"
  );
  const contentState = ContentState.createFromBlockArray(
    contentBlock.contentBlocks
  );
  const editorState = EditorState.createWithContent(contentState);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [newNote, setNewNote] = useState(editorState);

  const [activeButton, setActiveButton] = useState(0);

  const [currency, setCurrency] = useState(
    selectedTransaction
      ? selectedTransaction.currency
      : {
          value: "EUR",
          label: "€",
        }
  );

  const [selectedFileNames, setSelectedFileNames] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);

  const handleFileChange = (e) => {
    const files = e.target.files;
    const fileNames = [];
    for (let i = 0; i < files.length; i++) {
      fileNames.push(files[i].name);
    }
    setSelectedFileNames(fileNames);
    setSelectedFiles(files);
  };

  const removeFile = (fileName) => {
    // Convert FileList to Array if needed
    const filesArray = Array.from(selectedFiles);

    const newFileNames = selectedFileNames.filter((name) => name !== fileName);
    const newFiles = filesArray.filter((file) => file.name !== fileName);

    setSelectedFileNames(newFileNames);
    setSelectedFiles(newFiles);
  };

  const [amount, setAmount] = useState(
    selectedTransaction
      ? selectedTransaction.amount < 0
        ? selectedTransaction.amount * -1
        : selectedTransaction.amount
      : ""
  );

  const [selectedCompany, setSelectedCompany] = useState(
    selectedTransaction ? selectedTransaction.company : ""
  );
  const [selectedProperty, setSelectedProperty] = useState(
    selectedTransaction ? selectedTransaction.property : ""
  );

  const [selectedReason, setSelectedReason] = useState(
    selectedTransaction ? selectedTransaction.reason : ""
  );

  const [selectedMethodOfPayment, setSelectedMethodOfPayment] = useState(
    selectedTransaction
      ? selectedTransaction.methodOfPayment
      : paymentMethods[0]
  );

  const [selectedPartners, setSelectedPartners] = useState(
    selectedTransaction
      ? selectedTransaction.partners
      : props.partner
      ? {
          value: props.partner,
          label: `${props.partner.username} | ${props.partner.name} | ${props.partner.tax_id} | ${props.partner.amka} | ${props.partner.iban}`,
        }
      : ""
  );

  const [received, setReceived] = useState(
    selectedTransaction ? selectedTransaction.amount < 0 : true
  );

  const user = JSON.parse(localStorage.getItem("user"));

  const handleBlur = () => {
    if (amount) {
      setAmount(formatAmount(parseFloat(amount)));
    }
  };

  const handleReceiveSubmit = async (e) => {
    try {
      e.preventDefault();

      if (!amount || amount === "" || amount === "0") {
        setErrorMessage("Please enter an amount.");
        return;
      }

      if (!selectedProperty || selectedProperty === "") {
        setErrorMessage("Please select a property.");
        return;
      }

      if (!selectedReason || selectedReason === "") {
        setErrorMessage("Please select a reason.");
        return;
      }

      if (!selectedMethodOfPayment || selectedMethodOfPayment === "") {
        setErrorMessage("Please select a method of payment.");
        return;
      }

      const response = await axios.post(
        `${apiUrl}/transaction/receive-money-fund`,
        {
          amount: parseFloat(amount),
          currency: currency.value,
          company: selectedCompany.value._id,
          property: selectedProperty.value._id,
          reason: selectedReason.value._id,
          payment_method: selectedMethodOfPayment.value._id,
          partners: selectedPartners.value._id,

          notes: draftToHtml(convertToRaw(newNote.getCurrentContent())),
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      setSuccessMessage(response.data.message);
    } catch (error) {
      setErrorMessage(error.response.data.message);
    }
  };

  const handleSendSubmit = async (e) => {
    try {
      e.preventDefault();

      if (!amount || amount === "" || amount === "0") {
        setErrorMessage("Please enter an amount.");
        return;
      }

      if (!selectedProperty || selectedProperty === "") {
        setErrorMessage("Please select a property.");
        return;
      }

      if (!selectedReason || selectedReason === "") {
        setErrorMessage("Please select a reason.");
        return;
      }

      if (!selectedMethodOfPayment || selectedMethodOfPayment === "") {
        setErrorMessage("Please select a method of payment.");
        return;
      }
      const response = await axios.post(
        `${apiUrl}/transaction/send-money-fund`,
        {
          amount: parseFloat(amount),
          currency: currency.value,
          company: selectedCompany.value._id,
          property: selectedProperty.value._id,
          reason: selectedReason.value._id,
          payment_method: selectedMethodOfPayment.value._id,
          partners: selectedPartners.value._id,

          notes: draftToHtml(convertToRaw(newNote.getCurrentContent())),
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      setSuccessMessage(response.data.message);
    } catch (error) {
      setErrorMessage(error.response.data.message);
    }
  };

  useEffect(() => {
    const fetchProperties = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/property/getPropertiesNames`,
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        );
        setProperties(response.data.propertiesNames);
        setSelectedProperty(
          selectedTransaction
            ? selectedTransaction.property
            : {
                value: response.data.propertiesNames.find(
                  (property) => property.name === "Rhodes Holidays Office"
                ),
                label: response.data.propertiesNames.find(
                  (property) => property.name === "Rhodes Holidays Office"
                ).name,
              }
        );
      } catch (error) {
        console.log(error);
      }
    };

    const fetchUsers = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/auth/getUsersBankingDetails`,
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        );

        setPartners(response.data.users);
      } catch (error) {
        console.error("Failed to fetch users: ", error);
      }
    };

    const fetchCompanies = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${apiUrl}/company`, {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        });
        setCompanies(response.data.companies);
        setSelectedCompany({
          value: response.data.companies[0],
          label: `${response.data.companies[0].name} | ${response.data.companies[0].taxId} | ${response.data.companies[0].balance}€ | ${response.data.companies[0].iban}`,
        });
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    const fetchReasons = async () => {
      try {
        const response = await axios.get(`${apiUrl}/transaction-reason/`, {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        });
        setReasons(response.data.transactionReasons);
      } catch (error) {
        console.error("Failed to fetch reasons: ", error);
      }
    };

    const fetchMethodsOfPayment = async () => {
      try {
        const response = await axios.get(`${apiUrl}/payment-method/`, {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        });
        setMethodsOfPayment(response.data.paymentMethods);
        setSelectedMethodOfPayment({
          value: response.data.paymentMethods.find(
            (method) => method.title === "Cash"
          ),
          label: response.data.paymentMethods.find(
            (method) => method.title === "Cash"
          ).title,
        });
      } catch (error) {
        console.error("Failed to fetch methods of payment: ", error);
      }
    };

    fetchReasons();
    fetchMethodsOfPayment();

    fetchCompanies();

    fetchUsers();

    fetchProperties();
  }, []);

  useEffect(() => {
    const handleCompanyBalanceUpdate = (data) => {
      const updatedCompanies = companies.map((company) =>
        company._id === data._id
          ? { ...company, balance: data.balance }
          : company
      );
      setCompanies(updatedCompanies);

      if (selectedCompany.value._id === data._id) {
        setSelectedCompany({
          value: { ...selectedCompany.value, balance: data.balance },
          label: `${selectedCompany.value.name} | ${selectedCompany.value.taxId} | ${data.balance}€ | ${selectedCompany.value.iban}`,
        });
      }
    };

    socket.on("companyBalanceUpdate", handleCompanyBalanceUpdate);

    return () => {
      socket.off("companyBalanceUpdate", handleCompanyBalanceUpdate);
    };
  }, [companies, selectedCompany]);

  return (
    <div className={"transaction-form-wrapper"}>
      {!isLoading && (
        <form
          className={"transaction-form"}
          onSubmit={received ? handleReceiveSubmit : handleSendSubmit}
        >
          <div className="transaction-form-company">
            <label htmlFor="company" className="form-field-label">
              Business Account:
            </label>
            <Select
              options={companies.map((company) => ({
                value: company,
                label: `${company.name} | ${company.taxId} | ${company.balance} | ${company.iban}`,
              }))}
              value={selectedCompany}
              onChange={(selected) => {
                setSelectedCompany(selected);
              }}
              isClearable
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: "var(--primary-color)",
                  primary25: "var(--secondary-color)",
                },
              })}
              styles={{
                container: (base) => ({
                  ...base,
                  width: "100%", // Ensure container takes 100% width
                }),
                control: (base) => ({
                  ...base,
                  width: "100%", // Ensure control takes 100% width
                }),
                indicatorSeparator: (base) => ({
                  ...base,
                  display: "none",
                }),
                option: (base, state) => ({
                  ...base,
                  backgroundColor: state.isSelected
                    ? "var(--primary-color)"
                    : base.backgroundColor,
                  color: state.isSelected ? "white" : base.color,
                }),
              }}
            />
          </div>
          <div className="transaction-form-amount">
            <input
              type="number"
              className="transaction-form-amount__input"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              onBlur={handleBlur} // Format the amount when the user unfocuses
              placeholder="00,00"
              step={0.01}
              min={0}
              style={{
                width: amount
                  ? `${formatAmount(parseFloat(amount)).length * 25}px`
                  : "150px",
              }}
            />
            <Select
              className="transaction-form-amount__currency"
              value={currency}
              onChange={(e) => setCurrency(e)}
              options={currencyOptions}
              styles={{
                control: (base) => ({
                  ...base,
                  border: "none",
                  boxShadow: "none",
                }),

                indicatorSeparator: (base) => ({
                  ...base,
                  display: "none",
                }),

                option: (base, state) => ({
                  ...base,
                  backgroundColor: state.isSelected
                    ? "var(--primary-color)"
                    : base.backgroundColor,
                  color: state.isSelected ? "white" : base.color,
                }),
              }}
            />
          </div>

          <div className="transaction-form-partners">
            <div className="transaction-form-receive">
              <PrimaryButton
                text="Receive"
                color={
                  received ? "var(--primary-color)" : "var(--secondary-color)"
                }
                textColor="white"
                fontSize="15px"
                fontWeight="600"
                width="150px"
                borderRadius={received ? "50px" : "5px"}
                onClick={() => setReceived(true)}
              />
              <PrimaryButton
                text="Send"
                color={
                  received ? "var(--secondary-color)" : "var(--primary-color)"
                }
                textColor="white"
                fontSize="15px"
                fontWeight="600"
                width="150px"
                borderRadius={received ? "5px" : "50px"}
                onClick={() => setReceived(false)}
              />
            </div>
            <label htmlFor="partners" className="form-field-label">
              Partner:
            </label>
            <Select
              id="partners"
              name="partners"
              value={selectedPartners}
              onChange={(e) => setSelectedPartners(e)}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: "var(--primary-color)",
                  primary25: "var(--secondary-color)",
                },
              })}
              options={partners.map((partner) => ({
                value: partner,
                label: `${partner.username} | ${partner.name} | ${partner.tax_id} | ${partner.amka} | ${partner.iban}`,
              }))}
              isClearable
              styles={{
                container: (base) => ({
                  ...base,
                  width: "100%", // Ensure container takes 100% width
                }),
                control: (base) => ({
                  ...base,
                  width: "100%", // Ensure control takes 100% width
                }),
                indicatorSeparator: (base) => ({
                  ...base,
                  display: "none",
                }),
                option: (base, state) => ({
                  ...base,
                  backgroundColor: state.isSelected
                    ? "var(--primary-color)"
                    : base.backgroundColor,
                  color: state.isSelected ? "white" : base.color,
                }),
              }}
            />
          </div>
          <div className="transaction-form-reason">
            <label htmlFor="reason" className="form-field-label">
              Reason:
            </label>
            <Select
              id="reason"
              name="reason"
              value={selectedReason}
              onChange={(e) => setSelectedReason(e)}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: "var(--primary-color)",
                  primary25: "var(--secondary-color)",
                },
              })}
              options={reasons.map((reason) => ({
                value: reason,
                label: reason.title,
              }))}
              isClearable
              styles={{
                container: (base) => ({
                  ...base,
                  width: "100%", // Ensure container takes 100% width
                }),
                control: (base) => ({
                  ...base,
                  width: "100%", // Ensure control takes 100% width
                }),
                indicatorSeparator: (base) => ({
                  ...base,
                  display: "none",
                }),
                option: (base, state) => ({
                  ...base,
                  backgroundColor: state.isSelected
                    ? "var(--primary-color)"
                    : base.backgroundColor,
                  color: state.isSelected ? "white" : base.color,
                }),
              }}
            />
          </div>

          <div className="transaction-form-method-of-payment">
            <label htmlFor="methodOfPayment" className="form-field-label">
              Method of Payment:
            </label>
            <Select
              id="methodOfPayment"
              name="methodOfPayment"
              value={selectedMethodOfPayment}
              onChange={(e) => setSelectedMethodOfPayment(e)}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: "var(--primary-color)",
                  primary25: "var(--secondary-color)",
                },
              })}
              options={methodsOfPayment.map((method) => ({
                value: method,
                label: method.title,
              }))}
              isClearable
              styles={{
                container: (base) => ({
                  ...base,
                  width: "100%", // Ensure container takes 100% width
                }),
                control: (base) => ({
                  ...base,
                  width: "100%", // Ensure control takes 100% width
                }),
                indicatorSeparator: (base) => ({
                  ...base,
                  display: "none",
                }),
                option: (base, state) => ({
                  ...base,
                  backgroundColor: state.isSelected
                    ? "var(--primary-color)"
                    : base.backgroundColor,
                  color: state.isSelected ? "white" : base.color,
                }),
              }}
            />
          </div>
          <div className="transaction-form-preperty">
            <label htmlFor="property" className="form-field-label">
              Property:
            </label>

            <Select
              id="property"
              name="property"
              value={selectedProperty}
              onChange={(e) => setSelectedProperty(e)}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: "var(--primary-color)",
                  primary25: "var(--secondary-color)",
                },
              })}
              options={properties.map((property) => ({
                value: property,
                label: property.name,
              }))}
              isClearable
              styles={{
                container: (base) => ({
                  ...base,
                  width: "100%", // Ensure container takes 100% width
                }),
                control: (base) => ({
                  ...base,
                  width: "100%", // Ensure control takes 100% width
                }),
                indicatorSeparator: (base) => ({
                  ...base,
                  display: "none",
                }),
                option: (base, state) => ({
                  ...base,
                  backgroundColor: state.isSelected
                    ? "var(--primary-color)"
                    : base.backgroundColor,
                  color: state.isSelected ? "white" : base.color,
                }),
              }}
            />
          </div>
          <div className="transaction-form-notes">
            <label htmlFor="notes" className="form-field-label">
              Notes
              <span
                style={{
                  color: "var(--primary-color)",
                  fontSize: "15px",
                  fontWeight: "400",
                }}
              >
                (optional)
              </span>
              :
            </label>

            <DraftEditor editorState={newNote} setEditorState={setNewNote} />
          </div>
          {successMessage && <Alert severity="success">{successMessage}</Alert>}
          {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
          <div className="transaction-form-submit">
            <div className="form-group">
              <div className="form-field">
                <button
                  type="submit"
                  className="add-ticket-button__button"
                  style={{
                    backgroundColor: props.color || "var(--primary-color)",
                    color: props.textColor || "var(--primary-text-color)",
                    fontSize: props.fontSize || "15px",
                    fontWeight: props.fontWeight || "600",
                    width: props.width || "100%",
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default NewFundTransactionForm;
