import React, { useState, useEffect } from "react";

import {
  TextField,
  Alert,
  Tooltip,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  Select as MSelect,
  MenuItem,
} from "@mui/material";

import { AddCompanyForm, PopUpWindow } from "../";
import { Update, Delete, Balance } from "@mui/icons-material";
import Select, { components } from "react-select";
import apiUrl from "../api";

import axios from "axios";

const CustomOption = (props) => {
  return (
    <Tooltip
      title="color"
      placement="left"
      PopperProps={{
        style: { zIndex: 10001 },
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, -10],
            },
          },
        ],
        sx: {
          "& .MuiTooltip-tooltip": {
            fontSize: "14px",
            backgroundColor: `${props.data.tooltip}`,
            color: ` ${props.data.tooltip}`,
          },
        },
      }}
    >
      <div>
        <components.Option {...props} />
      </div>
    </Tooltip>
  );
};

const DeleteDialog = (props) => {
  const [selectedCategory, setSelectedCategory] = useState("");

  const handleCategoryDelete = async (e) => {
    e.preventDefault();
    props.setError("");
    props.setSuccess("");

    // Add your API call here to delete the category
    // console.log(`Bearer ${props.user.token.toString()}`);
    try {
      const response = await axios.delete(
        `${apiUrl}/ticket-options/deleteCategoryOption/${props.selectedCategory}`,
        {
          replaceCategory: selectedCategory,
          headers: {
            Authorization: `Bearer ${props.user.token}`,
          },
        }
      );

      props.setCategories(
        props.categories.filter((category) => category._id !== selectedCategory)
      );

      props.setSuccess("Category deleted successfully.");
      props.onClose();
    } catch (error) {
      console.log(error);
      props.setError("Failed to delete category. Please try again.");
      props.onClose();
    }
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Delete Category"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Select a category to replace the deleted category with.
        </DialogContentText>

        <MSelect
          value={selectedCategory}
          onChange={(e) => setSelectedCategory(e.target.value)}
          fullWidth
          sx={{
            width: "100%",
          }}
        >
          {props.categories
            .filter((category) => category._id !== props.selectedCategory)
            .map((category) => (
              <MenuItem key={category._id} value={category._id}>
                {category.title}
              </MenuItem>
            ))}
        </MSelect>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>Cancel</Button>
        <Button
          onClick={handleCategoryDelete}
          autoFocus
          disabled={selectedCategory === ""}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const EditCompany = () => {
  const [categoryName, setCategoryName] = useState("");
  const [categoryColor, setCategoryColor] = useState("#FA0808");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const [popUpWindow, setPopUpWindow] = useState(false);

  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    const fetchTransactionReason = async () => {
      try {
        const response = await axios.get(`${apiUrl}/company/`, {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        });
        setCategories(response.data.companies);
      } catch (error) {
        setError("Failed to fetch companies. Please try again.");
      }
    };
    fetchTransactionReason();
  }, []);

  const handleCategorySelect = (selectedOption) => {
    setSelectedCategory(selectedOption.value);
    setCategoryName(selectedOption.label);
    setCategoryColor(selectedOption.tooltip);
  };

  const handleEditCompany = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");

    if (categoryName === "") {
      return setError("Category name is required.");
    }

    try {
      const response = await axios.put(
        `${apiUrl}/transaction-reason/update/${selectedCategory}`,
        {
          title: categoryName,
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      setCategories(
        [...categories].map((category) => {
          if (category._id === selectedCategory) {
            return {
              ...category,
              title: categoryName,
              color: categoryColor,
            };
          }
          return category;
        })
      );

      setSuccess("Transaction Reason updated successfully.");
    } catch (error) {
      if (error.response && error.response.status === 409) {
        setError("Transaction Reason already exists.");
      } else {
        setError("Failed to update Transaction Reason. Please try again.");
      }
    }
  };

  return (
    <div className="update-category-option-wrapper">
      {error && <Alert severity="error">{error}</Alert>}
      {success && <Alert severity="success">{success}</Alert>}
      <h4
        style={{
          marginBottom: "10px",
          fontWeight: "600",
        }}
      >
        Edit Company
      </h4>
      <Select
        value={selectedCategory}
        onChange={(selectedOption) => {
          setSelectedCategory(selectedOption);
        }}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: "var(--secondary-color)",
            primary25: "var(--tertiary-color)",
          },
        })}
        options={[
          ...categories.map((category) => ({
            value: category,
            label: category.name,
          })),
        ]}
        // components={{ Option: CustomOption }}
        styles={{
          menu: (base) => ({
            ...base,
            zIndex: 1000, // Adjust this value as needed, ensuring it is higher than the z-index of other components
          }),
        }}
      />

      {selectedCategory && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <AddCompanyForm
            edit={true}
            company={{
              _id: selectedCategory.value._id,
              name: selectedCategory.value.name,
              bank: selectedCategory.value.bank,
              iban: selectedCategory.value.iban,
              balance: selectedCategory.value.balance,
              swiftBic: selectedCategory.value.swiftBic,
              taxId: selectedCategory.value.taxId,
            }}
          />
        </div>
      )}
    </div>
  );
};

export default EditCompany;
