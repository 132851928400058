import React, { useState, useEffect } from "react";

import { Avatar, Box, Tooltip } from "@mui/material";
import {
  Profile as ProfileComponent,
  Loader,
  OwnerForm,
  AddOwner,
} from "../../components";
import Select from "react-select";
import { PageStructure } from "..";
import apiUrl from "../../components/api";
import axios from "axios";

const getColor = (name) => {
  let hash = 0;
  for (let i = 0; i < name.length; i++) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }
  const color = `hsl(${hash % 360}, 100%, 30%)`;
  return color;
};

const EditOwner = () => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const [loading, setLoading] = useState(true);

  const [owners, setOwners] = useState([]);
  const [selectedOwner, setSelectedOwner] = useState(null);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchOwners = async () => {
      try {
        const response = await axios.get(`${apiUrl}/auth/ownersNameAndVat`, {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        });
        setOwners(response.data.owners);
        setLoading(false);
      } catch (error) {
        setError("Failed to fetch owners. Please try again.");
      }
    };
    fetchOwners();
  }, []);

  return (
    <PageStructure>
      <h2>Owners</h2>
      {/* <AddOwner /> */}

      <Select
        options={owners.map((owner) => ({
          value: owner._id,
          label: `${owner.name} - ${owner.tax_id}`,
        }))}
        placeholder="Select owners..."
        onChange={(selectedOptions) => {
          setSelectedOwner(selectedOptions.value);
        }}
      />

      {loading && <Loader />}
      {selectedOwner && (
        <AddOwner
            user={selectedOwner}
            edit={true}
        />
      )}
    </PageStructure>
  );
};

export default EditOwner;
