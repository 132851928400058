import React, { useState, useEffect } from "react";
import axios from "axios";
import { Box, Avatar, Tooltip, Chip } from "@mui/material";
import {
  Article,
  Download,
  Send,
  AttachFile,
  Delete,
  HowToReg,
} from "@mui/icons-material";
import Select, { components } from "react-select";
import io from "socket.io-client";
import {
  Editor,
  ContentState,
  EditorState,
  convertFromRaw,
  convertFromHTML,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

import apiUrl from "../api";

import "./ViewTransaction.css";

const sitelocation = `${apiUrl}/`;

const convertHtmlToEditorState = (html) => {
  const contentBlock = convertFromHTML(html);
  const contentState = ContentState.createFromBlockArray(
    contentBlock.contentBlocks,
    contentBlock.entityMap
  );
  return EditorState.createWithContent(contentState);
};

// Function to generate a color based on the username
const getColor = (name) => {
  let hash = 0;
  for (let i = 0; i < name.length; i++) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }
  const color = `hsl(${hash % 360}, 100%, 30%)`;
  return color;
};

const findContrastColor = (hexColor) => {
  const r = parseInt(hexColor.substr(1, 2), 16);
  const g = parseInt(hexColor.substr(3, 2), 16);
  const b = parseInt(hexColor.substr(5, 2), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? "#000" : "#fff";
};

function formatDate(date) {
  date = new Date(date);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
}

const formatDateWithTime = (date) => {
  date = new Date(date);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  return `${day}/${month}/${year} ${hours}:${minutes}`;
};

const currencyOptions = [
  {
    value: "EUR",
    label: "€",
  },
  {
    value: "USD",
    label: "$",
  },
  {
    value: "GBP",
    label: "£",
  },
  {
    value: "JPY",
    label: "¥",
  },
  {
    value: "RUB",
    label: "₽",
  },
];

function formatAmount(amount) {
  return amount.toFixed(2);
}

const socket = io(`${apiUrl}/`);

const checkFileType = (fileString) => {
  return fileString.split(".").pop().toLowerCase();
};

const isImageFile = (fileString) => {
  const imageExtensions = ["jpg", "jpeg", "png", "gif"];
  const fileExtension = checkFileType(fileString);
  return imageExtensions.includes(fileExtension);
};

const ViewTransaction = (props) => {
  const [transaction, setTransaction] = useState(props.transaction);

  console.log(transaction);
  const user = JSON.parse(localStorage.getItem("user"));
  const [editorState, setEditorState] = useState(
    convertHtmlToEditorState(transaction.notes)
  );

  return (
    <div className="view-transaction-wrapper">
      <div className="status-header">
        <Chip
          label={transaction.status.title}
          style={{
            backgroundColor: transaction.status.color,
            color: findContrastColor(transaction.status.color),
            fontWeight: "bold",
          }}
        />

        {transaction.is_payment && (
          <Chip
            label="Payment"
            style={{
              backgroundColor: "var(--primary-color)",
              color: "white",
              fontWeight: "bold",
            }}
          />
        )}

        <Chip
          label={formatDateWithTime(transaction.updated_at)}
          style={{
            backgroundColor: "var(--secondary-color)",
            color: "white",
            fontWeight: "bold",
          }}
        />
      </div>
      <div className="transaction-amount">
        <div
          className="transaction-form-amount"
          style={{
            width: transaction.amount
              ? `${formatAmount(parseFloat(transaction.amount)).length * 25}px`
              : "150px",
            alignSelf: "center",
          }}
        >
          <input
            type="number"
            className="transaction-form-amount__input"
            value={
              transaction.amount
                ? formatAmount(parseFloat(transaction.amount))
                : ""
            }
            onChange={(e) => {}}
            placeholder="00,00"
            step={0.01}
            min={0}
            style={{
              width: transaction.amount
                ? `${
                    formatAmount(parseFloat(transaction.amount)).length * 25
                  }px`
                : "150px",
            }}
            readOnly
          />
          <h3
            className="transaction-form-amount__currency"
            style={{
              width: transaction.amount
                ? `${
                    formatAmount(parseFloat(transaction.amount)).length * 25
                  }px`
                : "150px",
              paddingLeft: "10px",
            }}
          >
            {currencyOptions.find(
              (currency) => currency.value === transaction.currency
            )
              ? currencyOptions.find(
                  (currency) => currency.value === transaction.currency
                ).label
              : "t"}
          </h3>
        </div>
      </div>
      <hr />
      <div className="transaction-info__from">
        <h3 className="transaction-info__label">From:</h3>
        <div className="transaction-info__from__user">
          {transaction.sender && transaction.sender.name !== "" && (
            <>
              <Avatar
                sx={{
                  bgcolor: getColor(transaction.sender.name),
                  color: findContrastColor(getColor(transaction.sender.name)),
                }}
              >
                {transaction.sender.name.charAt(0).toUpperCase()}
              </Avatar>
              <h4>{transaction.sender.name}</h4>
            </>
          )}
          {(!transaction.sender || (transaction.sender && transaction.sender.name === "")) && <h4>-</h4>}
        </div>
      </div>

      {!transaction.is_payment && (
        <div className="transaction-info__to">
          <h3 className="transaction-info__label">To:</h3>
          <div className="transaction-info__to__user">
            {transaction.receiver && transaction.receiver.name !== "" && (
              <>
                <Avatar
                  sx={{
                    bgcolor: getColor(transaction.receiver.name),
                    color: findContrastColor(
                      getColor(transaction.receiver.name)
                    ),
                  }}
                >
                  {transaction.receiver.name.charAt(0).toUpperCase()}
                </Avatar>
                <h4>{transaction.receiver.name}</h4>
              </>
            )}
            {(!transaction.receiver || (transaction.receiver && transaction.receiver.name === "")) && <h4>-</h4>}
          </div>
        </div>
      )}

      {transaction.attachments && transaction.attachments.length > 0 && (
        <>
          <div
            className="seperator"
            style={{
              width: "100%",
              height: "1px",
              backgroundColor: "var(--primary-color)",
              margin: "10px 0",
            }}
          />

          <div className="card-main-attachments">
            <h3>Attachments:</h3>
            <div
              className="card-main-attachments-images"
              style={{
                backgroundColor: "var(--tertiary-color)",
                borderRadius: "10px",
              }}
            >
              {transaction.attachments
                .filter((attachment) => isImageFile(attachment))
                .map((attachment) => (
                  <img
                    src={sitelocation + attachment}
                    alt={attachment}
                    key={attachment}
                    onClick={() => {
                      window.open(sitelocation + attachment, "_blank");
                    }}
                  />
                ))}
            </div>
            <div className="card-main-attachments-files">
              {transaction.attachments
                .filter((attachment) => !isImageFile(attachment))
                .map((attachment) => (
                  <p
                    key={attachment}
                    onClick={() => {
                      window.open(sitelocation + attachment, "_blank");
                    }}
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <Article />{" "}
                    {attachment.slice(attachment.lastIndexOf("/") + 1)}{" "}
                    <Download />
                  </p>
                ))}
            </div>
          </div>
        </>
      )}

      {(transaction.approved_by || transaction.rejected_by) && (
        <div
          className="seperator"
          style={{
            width: "100%",
            height: "1px",
            backgroundColor: "var(--primary-color)",
            margin: "10px 0",
          }}
        />
      )}

      {transaction.approved_by && transaction.approved_by.name !== "" && (
        <div className="transaction-info__accepted">
          <h3 className="transaction-info__label">Approoved By:</h3>
          <div className="transaction-info__accepted__user">
            {transaction.approved_by !== "" && (
              <>
                <Avatar
                  sx={{
                    bgcolor: getColor(transaction.approved_by.name),
                    color: findContrastColor(
                      getColor(transaction.approved_by.name)
                    ),
                  }}
                >
                  {transaction.approved_by.name.charAt(0).toUpperCase()}
                </Avatar>
                <h4>{transaction.approved_by.name}</h4>
                <Chip
                  label={`Approved at: ${formatDateWithTime(
                    transaction.approved_at
                  )}`}
                  style={{
                    backgroundColor: "var(--tertiary-color)",
                    color: "white",
                    fontWeight: "bold",
                  }}
                />
              </>
            )}
            {transaction.approved_by && transaction.approved_by.name === "" && (
              <h4>-</h4>
            )}
          </div>
        </div>
      )}

      <div
        className="seperator"
        style={{
          width: "100%",
          height: "1px",
          backgroundColor: "var(--primary-color)",
          margin: "10px 0",
        }}
      />

      <div className="transaction-info__property">
        <h3 className="transaction-info__label">Property:</h3>
        <h4 className="transaction-info__info">{transaction.property.name}</h4>
      </div>

      <div className="transaction-info__reason">
        <h3 className="transaction-info__label">Reason:</h3>
        <h4 className="transaction-info__info">{transaction.reason.title}</h4>
      </div>

      <div className="transaction-info__payment_method">
        <h3 className="transaction-info__label">Payment Method:</h3>
        <h4 className="transaction-info__info">
          {transaction.payment_method.title}
        </h4>
      </div>

      <div
        className="seperator"
        style={{
          width: "100%",
          height: "1px",
          backgroundColor: "var(--primary-color)",
          margin: "10px 0",
        }}
      />

      <div className="transaction-info__notes-label">
        <h3>Notes:</h3>
      </div>
      <div className="transaction-info__notes">
        <Editor editorState={editorState} readOnly />
      </div>
      <div
        className="seperator"
        style={{
          width: "100%",
          height: "1px",
          backgroundColor: "var(--primary-color)",
          margin: "10px 0",
        }}
      />
      <Chip
        label={`Created at: ${formatDateWithTime(transaction.created_at)}`}
        style={{
          backgroundColor: "var(--tertiary-color)",
          color: "white",
          fontWeight: "bold",
        }}
      />
    </div>
  );
};

export default ViewTransaction;
