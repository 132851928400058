import React, { useState, useEffect } from "react";

import { Avatar, Box, Tooltip } from "@mui/material";
import { Profile as ProfileComponent, Loader } from "../../components";
import { PageStructure } from "../../pages";
import apiUrl from "../../components/api";
import axios from "axios";

import "./Profile.css";
import { CheckBox } from "@mui/icons-material";
const getColor = (name) => {
  let hash = 0;
  for (let i = 0; i < name.length; i++) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }
  const color = `hsl(${hash % 360}, 100%, 30%)`;
  return color;
};

const Profile = () => {

  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUser = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${apiUrl}/auth/${user._id}`, {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        });

        localStorage.setItem("user", JSON.stringify(response.data.user));
        setUser(response.data.user);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    fetchUser();
  }, []);

  return (
    <PageStructure>
      <h2>Profile</h2>
      {!loading && <ProfileComponent user={user} />}

      {loading && <Loader />}
    </PageStructure>
  );
};

export default Profile;
