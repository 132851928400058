import React, { useState, useEffect, useMemo, useRef } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Tooltip,
  Typography,
  Chip,
  TableSortLabel,
  Button,
  TextField,
  Alert,
  Avatar,
  Checkbox,
  Pagination,
} from "@mui/material";
import { ContentState, EditorState, convertFromHTML } from "draft-js";
import Select from "react-select";
import { debounce } from "lodash"; // Import debounce function from lodash

import {
  Loader,
  PopUpWindow,
  ViewArrivalForm,
  DraftEditor,
  CreateArrivalButton,
  EditArrivalForm,
  PrimaryButton,
  ViewTransaction,
} from "../../components";
import apiUrl from "../api";

import {
  CheckCircle,
  Cancel,
  Pending,
  Check,
  Close,
  RemoveRedEye,
} from "@mui/icons-material/";
import io from "socket.io-client";
import axios from "axios";

import "./TransactionTable.css";

function formatDate(date) {
  date = new Date(date);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
}

function formatDateCalendar(date) {
  date = new Date(date);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${year}-${month}-${day}`;
}

const getColor = (name) => {
  let hash = 0;
  for (let i = 0; i < name.length; i++) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }
  const color = `hsl(${hash % 360}, 100%, 30%)`;
  return color;
};

const findContrastColor = (hexColor) => {
  const r = parseInt(hexColor.substr(1, 2), 16);
  const g = parseInt(hexColor.substr(3, 2), 16);
  const b = parseInt(hexColor.substr(5, 2), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? "#000" : "#fff";
};

const findDueDateColor = (dueDate, selectedDay) => {
  const date = new Date(dueDate);
  const today = new Date(selectedDay);
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  const dayAfterTomorrow = new Date(today);
  dayAfterTomorrow.setDate(dayAfterTomorrow.getDate() + 2);
  if (date <= today) {
    return "#00F7FF";
  } else if (date <= dayAfterTomorrow) {
    return "#FF8400";
  } else {
    return "#00FF00";
  }
};

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const UserChip = ({ user, tasks, onClick, isActive }) => {
  return (
    <div className={`user-chip ${isActive ? "active" : ""}`} onClick={onClick}>
      <Avatar
        style={{
          backgroundColor: getColor(user.name),
          width: "30px",
          height: "30px",
          fontSize: "20px",
        }}
      >
        {user.name.charAt(0).toUpperCase()}
      </Avatar>
      <div>
        <p>
          {user.name} ({tasks})
        </p>
      </div>
    </div>
  );
};

const socket = io(`${apiUrl}/`);

const currencyOptions = [
  {
    value: "EUR",
    label: "€",
  },
  {
    value: "USD",
    label: "$",
  },
  {
    value: "GBP",
    label: "£",
  },
  {
    value: "JPY",
    label: "¥",
  },
  {
    value: "RUB",
    label: "₽",
  },
];

function formatAmount(amount) {
  return amount.toFixed(2);
}
const TransactionTable = (props) => {
  const [companies, setCompanies] = useState([]);

  const [selectedCompany, setSelectedCompany] = useState();

  const [showPopUpAccepted, setShowPopUpAccepted] = useState(false);
  const [showPopUpAcceptedPayment, setShowPopUpAcceptedPayment] =
    useState(false);
  const [showPopUpRejected, setShowPopUpRejected] = useState(false);
  const [showPopUpView, setShowPopUpView] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [amount, setAmount] = useState(0);
  const [selectedTransaction, setSelectedTransaction] = useState("");
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);

  const user = JSON.parse(localStorage.getItem("user"));

  const [transactions, setTransactions] = useState([]);

  const [sortField, setSortField] = useState("updated_at");
  const [sortOrder, setSortOrder] = useState(-1); // -1 for descending, 1 for ascending

  const [properties, setProperties] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState();

  const [reasons, setReasons] = useState([]);
  const [selectedReason, setSelectedReason] = useState();

  const [paymentMethods, setPaymentMethods] = useState([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState();

  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState();

  const [companyToSearch, setCompanyToSearch] = useState("");

  const [selectedDayFrom, setSelectedDayFrom] = useState("");
  const [selectedDayTo, setSelectedDayTo] = useState("");

  const [selectedTimestamp, setSelectedTimestamp] = useState("");

  const handleSortChange = (field) => {
    if (sortField === field) {
      // Toggle sort order if the same field was clicked
      setSortOrder(sortOrder * -1);
    } else {
      // Set new field and default to ascending
      setSortField(field);
      setSortOrder(1);
    }
  };

  const handleBlur = () => {
    if (amount) {
      setAmount(formatAmount(parseFloat(amount)));
    }
  };

  const handleAcceptTransaction = async (
    selectedTransaction,
    selectedCompany,
    amount
  ) => {
    try {
      const response = await axios.put(
        `${apiUrl}/transaction/approve/${selectedTransaction._id}`,
        {
          company: selectedCompany.value._id,
          amount: parseFloat(amount),
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      setSuccess("Transaction approved successfully!");
      setError("");

      setTimeout(() => {
        setSuccess("");
        setShowPopUpAccepted(false);
        setAmount(0);
      }, 1000);
    } catch (error) {
      setError(error.response.data.message);
      console.log(error.response.data.message);
    }
  };

  const handleAcceptPaymentTransaction = async (
    selectedTransaction,
    amount
  ) => {
    try {
      const response = await axios.put(
        `${apiUrl}/transaction/approve-payment/${selectedTransaction._id}`,
        {
          amount: parseFloat(amount),
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      setSuccess("Transaction approved successfully!");
      setError("");

      setTimeout(() => {
        setSuccess("");
        setShowPopUpAccepted(false);
        setAmount(0);
      }, 1000);
    } catch (error) {
      setError(error.response.data.message);
      console.log(error.response.data.message);
    }
  };

  const handleAcceptSendTransaction = async (selectedTransaction, amount) => {
    try {
      const response = await axios.put(
        `${apiUrl}/transaction/approve-send-money/${selectedTransaction._id}`,
        {
          amount: parseFloat(amount),
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      setSuccess("Transaction approved successfully!");
      setError("");

      setTimeout(() => {
        setSuccess("");
        setShowPopUpAccepted(false);
        setAmount(0);
      }, 1000);
    } catch (error) {
      setError(error.response.data.message);
      console.log(error.response.data.message);
    }
  };
  const handleAcceptReceiveTransaction = async (
    selectedTransaction,
    amount
  ) => {
    try {
      const response = await axios.put(
        `${apiUrl}/transaction/approve-receive-money/${selectedTransaction._id}`,
        {
          amount: parseFloat(amount),
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      setSuccess("Transaction approved successfully!");
      setError("");

      setTimeout(() => {
        setSuccess("");
        setShowPopUpAccepted(false);
        setAmount(0);
      }, 1000);
    } catch (error) {
      setError(error.response.data.message);
      console.log(error.response.data.message);
    }
  };

  const handleRejectTransaction = async (selectedTransaction) => {
    try {
      const response = await axios.put(
        `${apiUrl}/transaction/reject/${selectedTransaction._id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      setSuccess("Transaction rejected successfully!");
      setError("");

      setTimeout(() => {
        setSuccess("");
        setShowPopUpRejected(false);
        setAmount(0);
      }, 1000);
    } catch (error) {
      setError(error.response.data.message);
      console.log(error.response.data.message);
    }
  };

  useEffect(() => {
    const fetchTransactions = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${apiUrl}/transaction?page=${page}&sortField=${sortField}&sortOrder=${
            sortOrder === 1 ? "asc" : "desc"
          }&property=${
            selectedProperty ? selectedProperty.value._id : ""
          }&reason=${
            selectedReason ? selectedReason.value._id : ""
          }&paymentMethod=${
            selectedPaymentMethod ? selectedPaymentMethod.value._id : ""
          }&company=${companyToSearch ? companyToSearch.value._id : ""}&user=${
            selectedUser ? selectedUser.value._id : ""
          }&from=${selectedDayFrom}&to=${selectedDayTo}&timestamp=${
            selectedTimestamp ? selectedTimestamp.value : ""
          }
          `,
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        );
        setTransactions(
          response.data.transactions.map((transaction) => ({
            _id: transaction._id,
            date: formatDate(transaction.transaction_date),
            amount: transaction.amount,
            currency: transaction.currency,
            reason: transaction.reason,
            payment_method: transaction.payment_method,
            status: transaction.transaction_status,
            receiver: transaction.is_receiver
              ? transaction.user
              : transaction.company
              ? transaction.company
              : "",
            sender: transaction.is_receiver
              ? transaction.company
                ? transaction.company
                : transaction.is_payment
                ? transaction.user
                : ""
              : transaction.user,
            property: transaction.property,
            notes: transaction.notes,
            is_payment: transaction.is_payment,
            is_request: transaction.is_request,
            attachments: transaction.attachments,
            created_at: transaction.created_at,
            updated_at: transaction.updated_at,
            approved_by: transaction.approved_by,
            rejected_by: transaction.rejected_by,
            approved_at: transaction.approved_at,
            rejected_at: transaction.rejected_at,
          }))
        );
        setTotalItems(response.data.totalItems);
      } catch (error) {
        console.log(error);
      }
      setIsLoading(false);
    };

    const fetchTransactionsByUser = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${apiUrl}/transaction/?page=${page}?sortField=${sortField}?sortOrder=${sortOrder}&property=${
            selectedProperty ? selectedProperty.value._id : ""
          }&reason=${
            selectedReason ? selectedReason.value._id : ""
          }&paymentMethod=${
            selectedPaymentMethod ? selectedPaymentMethod.value._id : ""
          }&company=${companyToSearch ? companyToSearch.value._id : ""}&user=${
            props.user ? props.user : ""
          }&from=${selectedDayFrom}&to=${selectedDayTo}`,

          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        );
        setTotalItems(response.data.totalItems);
        setTransactions(
          response.data.transactions.map((transaction) => ({
            _id: transaction._id,
            date: formatDate(transaction.transaction_date),
            amount: transaction.amount,
            currency: transaction.currency,
            reason: transaction.reason,
            payment_method: transaction.payment_method,
            status: transaction.transaction_status,
            receiver: transaction.is_receiver
              ? transaction.user
              : transaction.company
              ? transaction.company
              : "",
            sender: transaction.is_receiver
              ? transaction.company
                ? transaction.company
                : transaction.is_payment
                ? transaction.user
                : ""
              : transaction.user,
            property: transaction.property,
            notes: transaction.notes,
            is_payment: transaction.is_payment,
            is_request: transaction.is_request,
            attachments: transaction.attachments,
            created_at: transaction.created_at,
            updated_at: transaction.updated_at,
            approved_by: transaction.approved_by,
            rejected_by: transaction.rejected_by,
            approved_at: transaction.approved_at,
            rejected_at: transaction.rejected_at,
          }))
        );
      } catch (error) {
        console.log(error);
      }
      setIsLoading(false);
    };

    if (props.user) {
      fetchTransactionsByUser();
    } else {
      fetchTransactions();
    }
  }, [
    page,
    sortField,
    sortOrder,
    selectedProperty,
    selectedReason,
    selectedPaymentMethod,
    companyToSearch,
    selectedUser,
    selectedDayFrom,
    selectedDayTo,
  ]);

  useEffect(() => {
    const fetchProperties = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/property/getPropertiesNames`,
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        );
        setProperties(response.data.propertiesNames);
      } catch (error) {
        console.log(error);
      }
    };

    const fetchUsers = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/auth/getUsersBankingDetails`,
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        );

        setUsers(response.data.users);
      } catch (error) {
        console.error("Failed to fetch users: ", error);
      }
    };

    const fetchCompanies = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${apiUrl}/company`, {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        });
        setCompanies(response.data.companies);
        setSelectedCompany({
          value: response.data.companies[0],
          label: `${response.data.companies[0].name} | ${response.data.companies[0].taxId} | ${response.data.companies[0].balance}€ | ${response.data.companies[0].iban}`,
        });
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    const fetchReasons = async () => {
      try {
        const response = await axios.get(`${apiUrl}/transaction-reason/`, {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        });
        setReasons(response.data.transactionReasons);
      } catch (error) {
        console.error("Failed to fetch reasons: ", error);
      }
    };

    const fetchMethodsOfPayment = async () => {
      try {
        const response = await axios.get(`${apiUrl}/payment-method/`, {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        });
        setPaymentMethods(response.data.paymentMethods);
      } catch (error) {
        console.error("Failed to fetch methods of payment: ", error);
      }
    };

    fetchReasons();
    fetchMethodsOfPayment();

    fetchCompanies();

    fetchUsers();

    fetchProperties();
  }, [user.token]);

  useEffect(() => {
    const handleNewTransaction = (newTransaction) => {
      setTransactions((transactions) => [
        {
          ...newTransaction,
          date: formatDate(newTransaction.transaction_date),
        },
        ...transactions,
      ]);
    };

    const handleUpdatedTransaction = (updatedTransaction) => {
      setTransactions((transactions) =>
        transactions.map((transaction) =>
          transaction._id === updatedTransaction._id
            ? {
                ...updatedTransaction,
                date: formatDate(updatedTransaction.transaction_date),
              }
            : transaction
        )
      );

      if (selectedTransaction._id === updatedTransaction._id) {
        setSelectedTransaction({
          ...updatedTransaction,
          date: formatDate(updatedTransaction.transaction_date),
        });
      }
    };

    const handleCompanyBalanceUpdate = (data) => {
      const updatedCompanies = companies.map((company) =>
        company._id === data._id
          ? { ...company, balance: data.balance }
          : company
      );
      setCompanies(updatedCompanies);

      if (selectedCompany.value._id === data._id) {
        setSelectedCompany({
          value: { ...selectedCompany.value, balance: data.balance },
          label: `${selectedCompany.value.name} | ${selectedCompany.value.taxId} | ${data.balance}€ | ${selectedCompany.value.iban}`,
        });
      }
    };

    socket.on("companyBalanceUpdate", handleCompanyBalanceUpdate);
    socket.on("newTransaction", handleNewTransaction);
    socket.on("updateTransaction", handleUpdatedTransaction);

    return () => {
      socket.off("companyBalanceUpdate", handleCompanyBalanceUpdate);
      socket.off("newTransaction", handleNewTransaction);
      socket.off("updateTransaction", handleUpdatedTransaction);
    };
  }, [companies, selectedTransaction._id, transactions]);

  return (
    <div
      className="transaction-table"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "20px",
        width: "100%",
      }}
    >
      {showPopUpAccepted && selectedTransaction.is_request && (
        <PopUpWindow
          title="Accept Transaction"
          closePopUp={() => {
            setShowPopUpAccepted(false);

            setAmount(0);
          }}
          onClose={() => {
            setShowPopUpAccepted(false);
            setAmount(0);
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              padding: "50px",
              width: "100%",
            }}
          >
            <div
              className="transaction-form-amount"
              style={{
                width: amount
                  ? `${formatAmount(parseFloat(amount)).length * 25}px`
                  : "150px",
                alignSelf: "center",
              }}
            >
              <input
                type="number"
                className="transaction-form-amount__input"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                onBlur={handleBlur} // Format the amount when the user unfocuses
                placeholder="00,00"
                step={0.01}
                min={0}
                style={{
                  width: amount
                    ? `${formatAmount(parseFloat(amount)).length * 25}px`
                    : "150px",
                }}
              />
              <h3
                className="transaction-form-amount__currency"
                style={{
                  width: amount
                    ? `${formatAmount(parseFloat(amount)).length * 25}px`
                    : "150px",
                  paddingLeft: "10px",
                }}
              >
                {currencyOptions.find(
                  (currency) => currency.value === selectedTransaction.currency
                )
                  ? currencyOptions.find(
                      (currency) =>
                        currency.value === selectedTransaction.currency
                    ).label
                  : "t"}
              </h3>
            </div>
            <Select
              options={companies.map((company) => ({
                value: company,
                label: `${company.name} | ${company.taxId} | ${company.balance} | ${company.iban}`,
              }))}
              value={selectedCompany}
              onChange={(selected) => {
                setSelectedCompany(selected);
              }}
              isClearable
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: "var(--primary-color)",
                  primary25: "var(--secondary-color)",
                },
              })}
              styles={{
                container: (base) => ({
                  ...base,
                  width: "100%", // Ensure container takes 100% width
                }),
                control: (base) => ({
                  ...base,
                  width: "100%", // Ensure control takes 100% width
                }),
                indicatorSeparator: (base) => ({
                  ...base,
                  display: "none",
                }),
                option: (base, state) => ({
                  ...base,
                  backgroundColor: state.isSelected
                    ? "var(--primary-color)"
                    : base.backgroundColor,
                  color: state.isSelected ? "white" : base.color,
                }),
              }}
            />
            <PrimaryButton
              text="Accept"
              icon={<Check />}
              onClick={() => {
                handleAcceptTransaction(
                  selectedTransaction,
                  selectedCompany,
                  amount
                );
              }}
            />

            {success !== "" && (
              <div className="success-message" style={{ color: "green" }}>
                {success}
              </div>
            )}

            {error !== "" && (
              <div className="error-message" style={{ color: "red" }}>
                {error}
              </div>
            )}
          </div>
        </PopUpWindow>
      )}

      {showPopUpAccepted &&
        (selectedTransaction.is_payment || !selectedTransaction.is_request) && (
          <PopUpWindow
            title="Accept Transaction"
            closePopUp={() => {
              setShowPopUpAccepted(false);

              setAmount(0);
            }}
            onClose={() => {
              setShowPopUpAccepted(false);
              setAmount(0);
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                padding: "50px",
                width: "100%",
              }}
            >
              <div
                className="transaction-form-amount"
                style={{
                  width: amount
                    ? `${formatAmount(parseFloat(amount)).length * 25}px`
                    : "150px",
                  alignSelf: "center",
                }}
              >
                <input
                  type="number"
                  className="transaction-form-amount__input"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  onBlur={handleBlur} // Format the amount when the user unfocuses
                  placeholder="00,00"
                  step={0.01}
                  min={0}
                  style={{
                    width: amount
                      ? `${formatAmount(parseFloat(amount)).length * 25}px`
                      : "150px",
                  }}
                />
                <h3
                  className="transaction-form-amount__currency"
                  style={{
                    width: amount
                      ? `${formatAmount(parseFloat(amount)).length * 25}px`
                      : "150px",
                    paddingLeft: "10px",
                  }}
                >
                  {currencyOptions.find(
                    (currency) =>
                      currency.value === selectedTransaction.currency
                  )
                    ? currencyOptions.find(
                        (currency) =>
                          currency.value === selectedTransaction.currency
                      ).label
                    : "t"}
                </h3>
              </div>

              <PrimaryButton
                text="Accept"
                icon={<Check />}
                onClick={() => {
                  if (selectedTransaction.is_payment) {
                    handleAcceptPaymentTransaction(selectedTransaction, amount);
                  } else if (selectedTransaction.is_receiver) {
                    handleAcceptReceiveTransaction(selectedTransaction, amount);
                  } else {
                    handleAcceptSendTransaction(selectedTransaction, amount);
                  }
                }}
              />

              {success !== "" && (
                <div className="success-message" style={{ color: "green" }}>
                  {success}
                </div>
              )}

              {error !== "" && (
                <div className="error-message" style={{ color: "red" }}>
                  {error}
                </div>
              )}
            </div>
          </PopUpWindow>
        )}

      {showPopUpRejected && (
        <PopUpWindow
          title="Reject Transaction"
          closePopUp={() => {
            setShowPopUpRejected(false);
          }}
          onClose={() => {
            setShowPopUpRejected(false);
            setAmount(0);
            setError("");
            setSuccess("");
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              padding: "50px",
              width: "100%",
            }}
          >
            <Typography variant="h6" style={{ textAlign: "center" }}>
              Are you sure you want to reject this transaction?
            </Typography>

            <div
              style={{
                display: "flex",
                gap: "20px",
                justifyContent: "center",
              }}
            >
              <PrimaryButton
                text="Reject Transaction"
                onClick={() => {
                  handleRejectTransaction(selectedTransaction);
                }}
              />
            </div>

            {success !== "" && (
              <div className="success-message" style={{ color: "green" }}>
                {success}
              </div>
            )}

            {error !== "" && (
              <div className="error-message" style={{ color: "red" }}>
                {error}
              </div>
            )}
          </div>
        </PopUpWindow>
      )}

      {isLoading && <Loader />}

      {showPopUpView && (
        <PopUpWindow
          closePopUp={() => {
            setShowPopUpView(false);
          }}
          onClose={() => {
            setShowPopUpView(false);
          }}
        >
          <ViewTransaction
            transaction={selectedTransaction}
            onClose={() => {
              setShowPopUpView(false);
            }}
          />
        </PopUpWindow>
      )}

      {!isLoading && (
        <form className="ticket-form" onSubmit={() => {}}>
          {/* Company*/}
          <div className="form-group">
            <div className="form-field">
              <label className="form-field-label" htmlFor="company">
                Company:
              </label>
              <Select
                value={companyToSearch}
                options={companies.map((company) => ({
                  value: company,
                  label: `${company.name} | ${company.taxId} | ${company.balance}€ | ${company.iban}`,
                }))}
                onChange={(selected) => {
                  setCompanyToSearch(selected);
                }}
                isClearable
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: "var(--primary-color)",
                    primary25: "var(--secondary-color)",
                  },
                })}
                styles={{
                  container: (base) => ({
                    ...base,
                    width: "100%", // Ensure container takes 100% width
                  }),
                  control: (base) => ({
                    ...base,
                    width: "100%", // Ensure control takes 100% width
                  }),
                  indicatorSeparator: (base) => ({
                    ...base,
                    display: "none",
                  }),
                  option: (base, state) => ({
                    ...base,
                    backgroundColor: state.isSelected
                      ? "var(--primary-color)"
                      : base.backgroundColor,
                    color: state.isSelected ? "white" : base.color,
                  }),
                }}
              />
            </div>
            {!props.user && (
              <div className="form-field">
                <label className="form-field-label" htmlFor="partner">
                  Partner:
                </label>
                <Select
                  value={selectedUser}
                  options={users.map((user) => ({
                    value: user,
                    label: `${user.username} | ${user.name} | ${user.tax_id} | ${user.amka} | ${user.iban}`,
                  }))}
                  onChange={(selected) => {
                    setSelectedUser(selected);
                  }}
                  isClearable
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: "var(--primary-color)",
                      primary25: "var(--secondary-color)",
                    },
                  })}
                  styles={{
                    container: (base) => ({
                      ...base,
                      width: "100%", // Ensure container takes 100% width
                    }),
                    control: (base) => ({
                      ...base,
                      width: "100%", // Ensure control takes 100% width
                    }),
                    indicatorSeparator: (base) => ({
                      ...base,
                      display: "none",
                    }),
                    option: (base, state) => ({
                      ...base,
                      backgroundColor: state.isSelected
                        ? "var(--primary-color)"
                        : base.backgroundColor,
                      color: state.isSelected ? "white" : base.color,
                    }),
                  }}
                />
              </div>
            )}

            <div className="form-field">
              <label className="form-field-label" htmlFor="timestamp">
                Timestamp:
              </label>
              <Select
              value={selectedTimestamp}
                options={[
                  {
                    value: "Today",
                    label: "Today",
                  },
                  {
                    value: "Yesterday",
                    label: "Yesterday",
                  },
                  {
                    value: "Last 3 Days",
                    label: "Last 3 Days",
                  },

                  {
                    value: "This Month",
                    label: "This Month",
                  },
                  {
                    value: "Last Month",
                    label: "Last Month",
                  },
                  {
                    value: "This Year",
                    label: "This Year",
                  },
                  {
                    value: "Last Year",
                    label: "Last Year",
                  },
                  {
                    value: "Custom",
                    label: "Custom",
                  },
                ]}
                onChange={(selected) => {
                  setSelectedTimestamp(selected);
                  if (selected) {
                    if (selected.value === "Today") {
                      setSelectedDayFrom(new Date());
                      setSelectedDayTo(new Date());
                    }
                    if (selected.value === "Yesterday") {
                      const yesterday = new Date();
                      yesterday.setDate(yesterday.getDate() - 1);
                      setSelectedDayFrom(yesterday);
                      setSelectedDayTo(yesterday);
                    }
                    if (selected.value === "Last 3 Days") {
                      const last3Days = new Date();
                      last3Days.setDate(last3Days.getDate() - 3);
                      setSelectedDayFrom(last3Days);
                      setSelectedDayTo(new Date());
                    }
                    if (selected.value === "This Month") {
                      const firstDay = new Date(
                        new Date().getFullYear(),
                        new Date().getMonth(),
                        1
                      );
                      setSelectedDayFrom(firstDay);
                      setSelectedDayTo(new Date());
                    }
                    if (selected.value === "Last Month") {
                      const firstDay = new Date(
                        new Date().getFullYear(),
                        new Date().getMonth() - 1,
                        1
                      );
                      const lastDay = new Date(
                        new Date().getFullYear(),
                        new Date().getMonth(),
                        0
                      );
                      setSelectedDayFrom(firstDay);
                      setSelectedDayTo(lastDay);
                    }
                    if (selected.value === "This Year") {
                      const firstDay = new Date(new Date().getFullYear(), 0, 1);
                      setSelectedDayFrom(firstDay);
                      setSelectedDayTo(new Date());
                    }
                    if (selected.value === "Last Year") {
                      const firstDay = new Date(
                        new Date().getFullYear() - 1,
                        0,
                        1
                      );
                      const lastDay = new Date(
                        new Date().getFullYear() - 1,
                        11,
                        31
                      );
                      setSelectedDayFrom(firstDay);
                      setSelectedDayTo(lastDay);
                    }
                    if (selected.value === "Custom") {
                      setSelectedDayFrom("");
                      setSelectedDayTo("");
                    }
                  }else{
                    setSelectedDayFrom("");
                    setSelectedDayTo("");
                  }
                }}
                isClearable
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: "var(--primary-color)",
                    primary25: "var(--secondary-color)",
                  },
                })}
                styles={{
                  container: (base) => ({
                    ...base,
                    width: "100%", // Ensure container takes 100% width
                  }),
                  control: (base) => ({
                    ...base,
                    width: "100%", // Ensure control takes 100% width
                  }),
                  indicatorSeparator: (base) => ({
                    ...base,
                    display: "none",
                  }),
                  option: (base, state) => ({
                    ...base,
                    backgroundColor: state.isSelected
                      ? "var(--primary-color)"
                      : base.backgroundColor,
                    color: state.isSelected ? "white" : base.color,
                  }),
                }}
              />
            </div>
          </div>

          {selectedTimestamp && selectedTimestamp.value === "Custom" && (
            <div className="form-group">
              <div className="form-field">
                <label className="form-field-label" htmlFor="from">
                  From:
                </label>
                <input
                  className="form-field-input"
                  type="date"
                  value={selectedDayFrom}
                  onChange={(e) => setSelectedDayFrom(e.target.value)}
                />
              </div>
              <div className="form-field">
                <label className="form-field-label" htmlFor="to">
                  To:
                </label>
                <input
                  className="form-field-input"
                  type="date"
                  value={selectedDayTo}
                  onChange={(e) => setSelectedDayTo(e.target.value)}
                />
              </div>
            </div>
          )}

          <div className="form-group">
            <div className="form-field">
              <label className="form-field-label" htmlFor="property">
                Property:
              </label>
              <Select
                value={selectedProperty}
                options={properties.map((property) => ({
                  value: property,
                  label: property.name,
                }))}
                onChange={(selected) => {
                  setSelectedProperty(selected);
                }}
                isClearable
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: "var(--primary-color)",
                    primary25: "var(--secondary-color)",
                  },
                })}
                styles={{
                  container: (base) => ({
                    ...base,
                    width: "100%", // Ensure container takes 100% width
                  }),
                  control: (base) => ({
                    ...base,
                    width: "100%", // Ensure control takes 100% width
                  }),
                  indicatorSeparator: (base) => ({
                    ...base,
                    display: "none",
                  }),
                  option: (base, state) => ({
                    ...base,
                    backgroundColor: state.isSelected
                      ? "var(--primary-color)"
                      : base.backgroundColor,
                    color: state.isSelected ? "white" : base.color,
                  }),
                }}
              />
            </div>
            <div className="form-field">
              <label className="form-field-label" htmlFor="reason">
                Reason:
              </label>
              <Select
                value={selectedReason}
                options={reasons.map((reason) => ({
                  value: reason,
                  label: reason.title,
                }))}
                onChange={(selected) => {
                  setSelectedReason(selected);
                }}
                isClearable
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: "var(--primary-color)",
                    primary25: "var(--secondary-color)",
                  },
                })}
                styles={{
                  container: (base) => ({
                    ...base,
                    width: "100%", // Ensure container takes 100% width
                  }),
                  control: (base) => ({
                    ...base,
                    width: "100%", // Ensure control takes 100% width
                  }),
                  indicatorSeparator: (base) => ({
                    ...base,
                    display: "none",
                  }),
                  option: (base, state) => ({
                    ...base,
                    backgroundColor: state.isSelected
                      ? "var(--primary-color)"
                      : base.backgroundColor,
                    color: state.isSelected ? "white" : base.color,
                  }),
                }}
              />
            </div>
            <div className="form-field">
              <label className="form-field-label" htmlFor="paymentMethod">
                Payment Method:
              </label>
              <Select
                value={selectedPaymentMethod}
                options={paymentMethods.map((method) => ({
                  value: method,
                  label: method.title,
                }))}
                onChange={(selected) => {
                  setSelectedPaymentMethod(selected);
                }}
                isClearable
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: "var(--primary-color)",
                    primary25: "var(--secondary-color)",
                  },
                })}
                styles={{
                  container: (base) => ({
                    ...base,
                    width: "100%", // Ensure container takes 100% width
                  }),
                  control: (base) => ({
                    ...base,
                    width: "100%", // Ensure control takes 100% width
                  }),
                  indicatorSeparator: (base) => ({
                    ...base,
                    display: "none",
                  }),
                  option: (base, state) => ({
                    ...base,
                    backgroundColor: state.isSelected
                      ? "var(--primary-color)"
                      : base.backgroundColor,
                    color: state.isSelected ? "white" : base.color,
                  }),
                }}
              />
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: "10px",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              alignSelf: "flex-start",
              justifySelf: "flex-start",
            }}
          >
            <Button
              variant="contained"
              color="secondary"
              style={{
                marginLeft: "10px",
                padding: "10px",
                width: "20%",
                backgroundColor: "var(--secondary-color)",
                color: "var(--primary-text-color)",
                fontSize: "15px",
                fontWeight: "500",
              }}
              onClick={() => {
                setCompanyToSearch("");
                setSelectedProperty("");
                setSelectedReason("");
                setSelectedPaymentMethod("");
                setSelectedUser("");
                setSelectedTimestamp("");
                setSelectedDayFrom("");
                setSelectedDayTo("");
              }}
            >
              Clear
            </Button>
          </div>
        </form>
      )}

      {!isLoading && (
        <div className="transaction-table">
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={sortField === "status"}
                      direction={sortOrder === 1 ? "asc" : "desc"}
                      onClick={() => handleSortChange("status")}
                    >
                      Status
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={sortField === "amount"}
                      direction={sortOrder === 1 ? "asc" : "desc"}
                      onClick={() => handleSortChange("amount")}
                    >
                      Amount
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={sortField === "property"}
                      direction={sortOrder === 1 ? "asc" : "desc"}
                      onClick={() => handleSortChange("property")}
                    >
                      Property
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={sortField === "reason"}
                      direction={sortOrder === 1 ? "asc" : "desc"}
                      onClick={() => handleSortChange("reason")}
                    >
                      Reason
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>Payment Method</TableCell>

                  <TableCell>Sender</TableCell>
                  <TableCell>Receiver</TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={sortField === "updated_at"}
                      direction={sortOrder === 1 ? "asc" : "desc"}
                      onClick={() => handleSortChange("updated_at")}
                    >
                      Last Update
                    </TableSortLabel>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {transactions.map((transaction, index) => (
                  <TableRow key={index}>
                    <TableCell sx={{ padding: "0px" }}>
                      <Tooltip title="View Transaction" placement="right">
                        <Button
                          onClick={() => {
                            setShowPopUpView(true);

                            setSelectedTransaction(transaction);
                          }}
                        >
                          <RemoveRedEye
                            sx={{ color: "var(--primary-color)" }}
                          />
                        </Button>
                      </Tooltip>

                      {transaction.status.title === "Pending" && (
                        <>
                          <Tooltip title="Accept Transaction" placement="right">
                            <Button
                              onClick={() => {
                                setShowPopUpAccepted(true);
                                setAmount(transaction.amount.toFixed(2));
                                setSelectedTransaction(transaction);
                              }}
                            >
                              <Check sx={{ color: "var(--primary-color)" }} />
                            </Button>
                          </Tooltip>

                          <Tooltip title="Reject Transaction" placement="right">
                            <Button
                              onClick={() => {
                                setShowPopUpRejected(true);
                                setSelectedTransaction(transaction);
                              }}
                            >
                              <Close sx={{ color: "var(--primary-color)" }} />
                            </Button>
                          </Tooltip>
                        </>
                      )}
                    </TableCell>
                    <TableCell>
                      <Chip
                        icon={
                          transaction.status.title === "Accepted" ? (
                            <CheckCircle style={{ color: "#fff" }} />
                          ) : transaction.status.title === "Rejected" ? (
                            <Cancel style={{ color: "#fff" }} />
                          ) : (
                            <Pending style={{ color: "#fff" }} />
                          )
                        }
                        label={transaction.status.title}
                        style={{
                          backgroundColor: transaction.status.color,
                          color: findContrastColor(transaction.status.color),
                        }}
                      />
                    </TableCell>
                    <TableCell style={{ whiteSpace: "nowrap" }}>
                      {transaction.amount.toFixed(2)}{" "}
                      {
                        currencyOptions.find(
                          (currency) => currency.value === transaction.currency
                        ).label
                      }
                    </TableCell>

                    <TableCell>{transaction.property.name}</TableCell>
                    <TableCell>{transaction.reason.title}</TableCell>
                    <TableCell>{transaction.payment_method.title}</TableCell>
                    <TableCell>
                      {transaction.sender && transaction.sender.name !== "" && (
                        <Tooltip title={transaction.sender.name}>
                          <Avatar
                            style={{
                              backgroundColor: getColor(
                                transaction.sender.name
                              ),
                              width: "30px",
                              height: "30px",
                              fontSize: "20px",
                            }}
                          >
                            {transaction.sender.name.charAt(0).toUpperCase()}
                          </Avatar>
                        </Tooltip>
                      )}
                    </TableCell>

                    <TableCell>
                      {transaction.receiver &&
                        transaction.receiver.name !== "" && (
                          <Tooltip title={transaction.receiver.name}>
                            <Avatar
                              style={{
                                backgroundColor: getColor(
                                  transaction.receiver.name
                                ),
                                width: "30px",
                                height: "30px",
                                fontSize: "20px",
                              }}
                            >
                              {transaction.receiver.name
                                .charAt(0)
                                .toUpperCase()}
                            </Avatar>
                          </Tooltip>
                        )}
                    </TableCell>
                    <TableCell>{formatDate(transaction.updated_at)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            m={2}
            sx={{ width: "95%" }}
          >
            <Typography>
              Showing {Math.min((page - 1) * 10 + 1, totalItems)}-
              {Math.min(page * 10, totalItems)} of {totalItems} items
            </Typography>

            <Pagination
              count={Math.ceil(totalItems / 10)}
              page={page}
              color="primary"
              onChange={(event, value) => {
                setPage(value);
              }}
            />
          </Box>
        </div>
      )}
    </div>
  );
};
export default TransactionTable;
