import React, { useState, useEffect } from "react";
import Select from "react-select";
import axios from "axios";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { EditorState, RichUtils, convertToRaw, convertFromRaw } from "draft-js";
import { Delete } from "@mui/icons-material";
import { Tooltip, Alert, TextField } from "@mui/material";
import { FileUpload } from "@mui/icons-material";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import apiUrl from "../api";

import { ContentState, convertFromHTML } from "draft-js";

import "./NewTransactionForm.css";
import PrimaryButton from "../primaryButton/PrimaryButton";
import DraftEditor from "../Editor/DraftEditor";

function formatDateData(date) {
  date = new Date(date);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${year}-${month}-${day}`;
}

function formatAmount(amount) {
  return amount.toFixed(2);
}

const NewTransactionForm = (props) => {
  const [selectedTransaction, setSelectedTransaction] = useState(
    props.selectedTransaction
  );

  const currencyOptions = [
    {
      value: "EUR",
      label: "€",
    },
    {
      value: "USD",
      label: "$",
    },
    {
      value: "GBP",
      label: "£",
    },
    {
      value: "JPY",
      label: "¥",
    },
    {
      value: "RUB",
      label: "₽",
    },
  ];

  const [properties, setProperties] = useState([]);
  const [reasons, setReasons] = useState([]);
  const [methodsOfPayment, setMethodsOfPayment] = useState([]);
  const [partners, setPartners] = useState([]);
  // Convert HTML string to EditorState
  const contentBlock = htmlToDraft(
    selectedTransaction ? selectedTransaction.notes : "<p></p>"
  );
  const contentState = ContentState.createFromBlockArray(
    contentBlock.contentBlocks
  );
  const editorState = EditorState.createWithContent(contentState);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [newNote, setNewNote] = useState(editorState);

  const [activeButton, setActiveButton] = useState(0);

  const [currency, setCurrency] = useState(
    selectedTransaction
      ? selectedTransaction.currency
      : {
          value: "EUR",
          label: "€",
        }
  );

  const [selectedFileNames, setSelectedFileNames] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);

  const user = JSON.parse(localStorage.getItem("user"));

  const handleRequestSubmit = async (e) => {
    e.preventDefault();

    if (!amount || amount === "" || amount === "0") {
      setErrorMessage("Please enter an amount.");
      return;
    }

    if (!selectedProperty || selectedProperty === "") {
      setErrorMessage("Please select a property.");
      return;
    }

    if (!selectedReason || selectedReason === "") {
      setErrorMessage("Please select a reason.");
      return;
    }

    if (!selectedMethodOfPayment || selectedMethodOfPayment === "") {
      setErrorMessage("Please select a method of payment.");
      return;
    }

    const data = {
      amount: parseFloat(amount),
      currency: currency.value,
      property: selectedProperty.value._id,
      reason: selectedReason.value._id,
      payment_method: selectedMethodOfPayment.value._id,
      notes: draftToHtml(convertToRaw(newNote.getCurrentContent())),
    };

    try {
      const response = await axios.post(
        `${apiUrl}/transaction/request/`,
        data,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      setSuccessMessage(response.data.message);
      setErrorMessage("");

      setTimeout(() => {
        props.closeModal();
      }, 1000);
    } catch (error) {
      setSuccessMessage("");
      setErrorMessage(error.response.data.message);
    }
  };

  const handleReceiveSubmit = async (e) => {
    e.preventDefault();

    if (!amount || amount === "" || amount === "0") {
      setErrorMessage("Please enter an amount.");
      return;
    }

    if (!selectedProperty || selectedProperty === "") {
      setErrorMessage("Please select a property.");
      return;
    }

    if (!selectedReason || selectedReason === "") {
      setErrorMessage("Please select a reason.");
      return;
    }

    if (!selectedMethodOfPayment || selectedMethodOfPayment === "") {
      setErrorMessage("Please select a method of payment.");
      return;
    }

    const data = {
      amount: parseFloat(amount),
      currency: currency.value,
      property: selectedProperty.value._id,
      reason: selectedReason.value._id,
      payment_method: selectedMethodOfPayment.value._id,
      notes: draftToHtml(convertToRaw(newNote.getCurrentContent())),
    };

    try {
      const response = await axios.post(
        `${apiUrl}/transaction/receive-money/`,
        data,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      setSuccessMessage(response.data.message);
      setErrorMessage("");

      setTimeout(() => {
        props.closeModal();
      }, 1000);
    } catch (error) {
      setSuccessMessage("");
      setErrorMessage(error.response.data.message);
    }
  };

  const handleSendSubmit = async (e) => {
    e.preventDefault();

    if (!amount || amount === "" || amount === "0") {
      setErrorMessage("Please enter an amount.");
      return;
    }

    if (!selectedProperty || selectedProperty === "") {
      setErrorMessage("Please select a property.");
      return;
    }

    if (!selectedReason || selectedReason === "") {
      setErrorMessage("Please select a reason.");
      return;
    }

    if (!selectedMethodOfPayment || selectedMethodOfPayment === "") {
      setErrorMessage("Please select a method of payment.");
      return;
    }

    const data = {
      amount: parseFloat(amount),
      currency: currency.value,
      property: selectedProperty.value._id,
      reason: selectedReason.value._id,
      payment_method: selectedMethodOfPayment.value._id,
      notes: draftToHtml(convertToRaw(newNote.getCurrentContent())),
    };

    try {
      const response = await axios.post(
        `${apiUrl}/transaction/send-money/`,
        data,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      setSuccessMessage(response.data.message);
      setErrorMessage("");

      setTimeout(() => {
        props.closeModal();
      }, 1000);
    } catch (error) {
      setSuccessMessage("");
      setErrorMessage(error.response.data.message);
    }
  };

  

  const handleBuySubmit = async (e) => {
    e.preventDefault();

    if (!amount || amount === "" || amount === "0") {
      setErrorMessage("Please enter an amount.");
      return;
    }

    if (!selectedProperty || selectedProperty === "") {
      setErrorMessage("Please select a property.");
      return;
    }

    if (!selectedReason || selectedReason === "") {
      setErrorMessage("Please select a reason.");
      return;
    }

    if (!selectedMethodOfPayment || selectedMethodOfPayment === "") {
      setErrorMessage("Please select a method of payment.");
      return;
    }

    const data = {
      amount: parseFloat(amount),
      currency: currency.value,
      property: selectedProperty.value._id,
      reason: selectedReason.value._id,
      payment_method: selectedMethodOfPayment.value._id,
      notes: draftToHtml(convertToRaw(newNote.getCurrentContent())),
      attachments: selectedFiles ? selectedFiles : [],
    };

    try {
      const formData = new FormData();
      formData.append("amount", data.amount);
      formData.append("currency", data.currency);
      formData.append("property", data.property);
      formData.append("reason", data.reason);
      formData.append("payment_method", data.payment_method);
      formData.append("notes", data.notes);
      data.attachments.forEach((file) => {
        formData.append("attachments", file);
      });

      const response = await axios.post(
        `${apiUrl}/transaction/request-payment/`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      setSuccessMessage(response.data.message);
      setErrorMessage("");

      setTimeout(() => {
        props.closeModal();
      }, 1000);
    } catch (error) {
      setSuccessMessage("");
      setErrorMessage(error.response.data.message);
    }
  };

  const handleFileChange = (e) => {
    const files = e.target.files;
    const newFiles = Array.from(files);
    const fileNames = newFiles.map((file) => file.name);
    const allFiles = [...selectedFiles, ...newFiles];
    setSelectedFileNames([...selectedFileNames, ...fileNames]);
    setSelectedFiles(allFiles);
  };

  const removeFile = (fileName) => {
    const filesArray = Array.from(selectedFiles);

    const newFileNames = selectedFileNames.filter((name) => name !== fileName);
    const newFiles = filesArray.filter((file) => file.name !== fileName);

    setSelectedFileNames(newFileNames);
    setSelectedFiles(newFiles);
  };

  const [amount, setAmount] = useState(
    selectedTransaction
      ? selectedTransaction.amount < 0
        ? selectedTransaction.amount * -1
        : selectedTransaction.amount
      : ""
  );

  const [selectedProperty, setSelectedProperty] = useState(
    selectedTransaction ? selectedTransaction.property : ""
  );

  const [selectedReason, setSelectedReason] = useState(
    selectedTransaction ? selectedTransaction.reason : ""
  );

  const [selectedMethodOfPayment, setSelectedMethodOfPayment] = useState(
    selectedTransaction ? selectedTransaction.methodOfPayment : null
  );

  const [selectedPartners, setSelectedPartners] = useState(
    selectedTransaction ? selectedTransaction.partners : ""
  );

  const [received, setReceived] = useState(
    selectedTransaction ? selectedTransaction.amount < 0 : true
  );

  const handleBlur = () => {
    if (amount) {
      setAmount(formatAmount(parseFloat(amount)));
    }
  };

  useEffect(() => {
    const fetchProperties = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/property/getPropertiesNames`,
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        );
        setProperties(response.data.propertiesNames);
        setSelectedProperty(
          selectedTransaction
            ? selectedTransaction.property
            : {
                value: response.data.propertiesNames.find(
                  (property) => property.name === "Rhodes Holidays Office"
                ),
                label: response.data.propertiesNames.find(
                  (property) => property.name === "Rhodes Holidays Office"
                ).name,
              }
        );
      } catch (error) {
        console.log(error);
      }
    };

    const fetchUsers = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/auth/getUsersBankingDetails`,
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        );

        setPartners(response.data.users);
      } catch (error) {
        console.error("Failed to fetch users: ", error);
      }
    };

    const fetchReasons = async () => {
      try {
        const response = await axios.get(`${apiUrl}/transaction-reason/`, {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        });
        setReasons(response.data.transactionReasons);
      } catch (error) {
        console.error("Failed to fetch reasons: ", error);
      }
    };

    const fetchMethodsOfPayment = async () => {
      try {
        const response = await axios.get(`${apiUrl}/payment-method/`, {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        });
        setMethodsOfPayment(response.data.paymentMethods);
        setSelectedMethodOfPayment({
          value: response.data.paymentMethods.find(
            (method) => method.title === "Cash"
          ),
          label: response.data.paymentMethods.find(
            (method) => method.title === "Cash"
          ).title,
        });
      } catch (error) {
        console.error("Failed to fetch methods of payment: ", error);
      }
    };

    fetchUsers();
    fetchReasons();
    fetchMethodsOfPayment();
    fetchProperties();
  }, []);

  return (
    <div className={"transaction-form-wrapper"}>
      <form
        className={"transaction-form"}
        onSubmit={
          activeButton === 0
            ? handleRequestSubmit
            : activeButton === 1
            ? 
              received
              ? handleReceiveSubmit
              : handleSendSubmit
              
            : handleBuySubmit
        }
      >
        <div className="transaction-form-amount">
          <input
            type="number"
            className="transaction-form-amount__input"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            onBlur={handleBlur} // Format the amount when the user unfocuses
            placeholder="00,00"
            step={0.01}
            min={0}
            style={{
              width: amount
                ? `${formatAmount(parseFloat(amount)).length * 25}px`
                : "150px",
            }}
          />
          <Select
            className="transaction-form-amount__currency"
            value={currency}
            onChange={(e) => setCurrency(e)}
            options={currencyOptions}
            styles={{
              control: (base) => ({
                ...base,
                border: "none",
                boxShadow: "none",
              }),

              indicatorSeparator: (base) => ({
                ...base,
                display: "none",
              }),

              option: (base, state) => ({
                ...base,
                backgroundColor: state.isSelected
                  ? "var(--primary-color)"
                  : base.backgroundColor,
                color: state.isSelected ? "white" : base.color,
              }),
            }}
          />
        </div>

        <div className="transaction-form-choice">
          <PrimaryButton
            key={0}
            text="Request"
            color={
              activeButton === 0
                ? "var(--primary-color)"
                : "var(--secondary-color)"
            }
            textColor="white"
            fontSize="15px"
            fontWeight="600"
            width="150px"
            borderRadius={activeButton === 0 ? "50px" : "5px"}
            onClick={() => setActiveButton(0)}
          />
          <PrimaryButton
            key={2}
            text="Buy"
            color={
              activeButton === 2
                ? "var(--primary-color)"
                : "var(--secondary-color)"
            }
            textColor="white"
            fontSize="15px"
            fontWeight="600"
            width="150px"
            borderRadius={activeButton === 2 ? "50px" : "5px"}
            onClick={() => setActiveButton(2)}
          />
          <PrimaryButton
            key={1}
            text="Partners"
            color={
              activeButton === 1
                ? "var(--primary-color)"
                : "var(--secondary-color)"
            }
            textColor="white"
            fontSize="15px"
            fontWeight="600"
            width="150px"
            borderRadius={activeButton === 1 ? "50px" : "5px"}
            onClick={() => setActiveButton(1)}
          />
        </div>

        {/* Partners */}
        {activeButton === 1 && (
          <div className="transaction-form-partners">
            {/* <label htmlFor="partners" className="form-field-label">
              Partners:
            </label> */}
            {/* <Select
              id="partners"
              name="partners"
              value={selectedPartners}
              onChange={(e) => setSelectedPartners(e)}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: "var(--primary-color)",
                  primary25: "var(--secondary-color)",
                },
              })}
              options={partners.map((partner) => ({
                value: partner,
                label: `${partner.username} | ${partner.name} | ${partner.tax_id} | ${partner.amka} | ${partner.iban}`,
              }))}
              isClearable
              styles={{
                container: (base) => ({
                  ...base,
                  width: "100%", // Ensure container takes 100% width
                }),
                control: (base) => ({
                  ...base,
                  width: "100%", // Ensure control takes 100% width
                }),
                indicatorSeparator: (base) => ({
                  ...base,
                  display: "none",
                }),
                option: (base, state) => ({
                  ...base,
                  backgroundColor: state.isSelected
                    ? "var(--primary-color)"
                    : base.backgroundColor,
                  color: state.isSelected ? "white" : base.color,
                }),
              }}
            /> */}
            <div className="transaction-form-receive">
              <PrimaryButton
                text="Receive"
                color={
                  received ? "var(--primary-color)" : "var(--secondary-color)"
                }
                textColor="white"
                fontSize="15px"
                fontWeight="600"
                width="150px"
                borderRadius={received ? "50px" : "5px"}
                onClick={() => setReceived(true)}
              />
              <PrimaryButton
                text="Give"
                color={
                  received ? "var(--secondary-color)" : "var(--primary-color)"
                }
                textColor="white"
                fontSize="15px"
                fontWeight="600"
                width="150px"
                borderRadius={received ? "5px" : "50px"}
                onClick={() => setReceived(false)}
              />
            </div>
          </div>
        )}

        {/* BUY BUTTON */}
        {activeButton === 2 && (
          <div className="transaction-form-invoice">
            <div className="transaction-form-invoice__upload">
              {/* Attachments  */}
              <div
                className="form-group"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "start",
                  alignItems: "start",
                }}
              >
                <Tooltip
                  title="Upload File"
                  PopperProps={{
                    sx: {
                      "& .MuiTooltip-tooltip": {
                        fontSize: "15px",
                      },
                    },
                    style: { zIndex: 10001, fontSize: "16px" },
                    modifiers: [
                      {
                        name: "offset",
                        options: {
                          offset: [0, -10],
                        },
                      },
                    ],
                  }}
                  arrow
                  placement="bottom"
                >
                  <div className="form-field">
                    <label
                      htmlFor="attachments"
                      className="form-field-label custom-file-upload"
                    >
                      Attachment:
                      <input
                        type="file"
                        className="form-field-input"
                        id="attachments"
                        name="attachments"
                        multiple
                        onChange={handleFileChange}
                      />
                      <FileUpload
                        sx={{
                          color: "var(--primary-color)",
                          fontSize: "40px",
                          cursor: "pointer",
                          alignSelf: "center",
                          pt: "10px",
                          pb: "10px",
                          "&:hover": {
                            color: "var(--secondary-color)",
                          },
                          transition: "color 0.3s",
                        }}
                      />
                    </label>
                  </div>
                </Tooltip>
                {selectedFileNames.length > 0 && (
                  <div className="file-list">
                    <p>Selected File:</p>
                    <ul>
                      {selectedFileNames.map((fileName, index) => (
                        <li
                          key={index}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          {fileName}
                          <Tooltip
                            title="Remove File"
                            PopperProps={{
                              sx: {
                                "& .MuiTooltip-tooltip": {
                                  fontSize: "15px",
                                },
                              },
                              style: { zIndex: 10001, fontSize: "16px" },
                              modifiers: [
                                {
                                  name: "offset",
                                  options: {
                                    offset: [0, -10],
                                  },
                                },
                              ],
                            }}
                            arrow
                            placement="right"
                          >
                            <Delete
                              sx={{
                                color: "var(--primary-color)",
                                fontSize: "20px",
                                cursor: "pointer",
                                "&:hover": {
                                  color: "var(--secondary-color)",
                                },
                                transition: "color 0.3s",
                                alignSelf: "center",
                                textAlign: "center",
                                padding: "10px",
                              }}
                              onClick={() => removeFile(fileName)}
                            />
                          </Tooltip>
                        </li>
                      ))}
                    </ul>
                    <p>Total Files: {selectedFileNames.length}</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        <div className="transaction-form-reason">
          <label htmlFor="reason" className="form-field-label">
            Reason:
          </label>
          <Select
            id="reason"
            name="reason"
            value={selectedReason}
            onChange={(e) => setSelectedReason(e)}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: "var(--primary-color)",
                primary25: "var(--secondary-color)",
              },
            })}
            options={reasons.map((reason) => ({
              value: reason,
              label: reason.title,
            }))}
            isClearable
            styles={{
              container: (base) => ({
                ...base,
                width: "100%", // Ensure container takes 100% width
              }),
              control: (base) => ({
                ...base,
                width: "100%", // Ensure control takes 100% width
              }),
              indicatorSeparator: (base) => ({
                ...base,
                display: "none",
              }),
              option: (base, state) => ({
                ...base,
                backgroundColor: state.isSelected
                  ? "var(--primary-color)"
                  : base.backgroundColor,
                color: state.isSelected ? "white" : base.color,
              }),
            }}
          />
        </div>

        <div className="transaction-form-method-of-payment">
          <label htmlFor="methodOfPayment" className="form-field-label">
            Method of Payment:
          </label>
          <Select
            id="methodOfPayment"
            name="methodOfPayment"
            value={selectedMethodOfPayment}
            onChange={(e) => setSelectedMethodOfPayment(e)}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: "var(--primary-color)",
                primary25: "var(--secondary-color)",
              },
            })}
            options={methodsOfPayment.map((method) => ({
              value: method,
              label: method.title,
            }))}
            isClearable
            styles={{
              container: (base) => ({
                ...base,
                width: "100%", // Ensure container takes 100% width
              }),
              control: (base) => ({
                ...base,
                width: "100%", // Ensure control takes 100% width
              }),
              indicatorSeparator: (base) => ({
                ...base,
                display: "none",
              }),
              option: (base, state) => ({
                ...base,
                backgroundColor: state.isSelected
                  ? "var(--primary-color)"
                  : base.backgroundColor,
                color: state.isSelected ? "white" : base.color,
              }),
            }}
          />
        </div>
        <div className="transaction-form-preperty">
          <label htmlFor="property" className="form-field-label">
            Property:
          </label>

          <Select
            id="property"
            name="property"
            value={selectedProperty}
            onChange={(e) => setSelectedProperty(e)}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: "var(--primary-color)",
                primary25: "var(--secondary-color)",
              },
            })}
            options={properties.map((property) => ({
              value: property,
              label: property.name,
            }))}
            isClearable
            styles={{
              container: (base) => ({
                ...base,
                width: "100%", // Ensure container takes 100% width
              }),
              control: (base) => ({
                ...base,
                width: "100%", // Ensure control takes 100% width
              }),
              indicatorSeparator: (base) => ({
                ...base,
                display: "none",
              }),
              option: (base, state) => ({
                ...base,
                backgroundColor: state.isSelected
                  ? "var(--primary-color)"
                  : base.backgroundColor,
                color: state.isSelected ? "white" : base.color,
              }),
            }}
          />
        </div>
        <div className="transaction-form-notes">
          <label htmlFor="notes" className="form-field-label">
            Notes
            <span
              style={{
                color: "var(--primary-color)",
                fontSize: "15px",
                fontWeight: "400",
              }}
            >
              (optional)
            </span>
            :
          </label>

          <DraftEditor editorState={newNote} setEditorState={setNewNote} />
        </div>
        {successMessage && <Alert severity="success">{successMessage}</Alert>}
        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
        <div className="transaction-form-submit">
          <div className="form-group">
            <div className="form-field">
              <button
                type="submit"
                className="add-ticket-button__button"
                style={{
                  backgroundColor: props.color || "var(--primary-color)",
                  color: props.textColor || "var(--primary-text-color)",
                  fontSize: props.fontSize || "15px",
                  fontWeight: props.fontWeight || "600",
                  width: props.width || "100%",
                }}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default NewTransactionForm;
