import React, { useEffect } from "react";
import { useLocation } from "react-router-dom"; // Import useLocation
import {
  AddTicketButton,
  Loader,
  NewFundTransactionForm,
  PrimaryButton,
  Sidebar,
  TaskManager,
  TicketTable,
  TransactionTable,
  UserTransactions as UserTransactionsComponent,
} from "../../components";

import Select from "react-select";
import { RequestQuote } from "@mui/icons-material";
import { PageStructure } from "..";
import axios from "axios";
import apiUrl from "../../components/api";

const UsersTransactions = (props) => {
  // This hook returns the current location object
  const location = useLocation();
  const me = JSON.parse(localStorage.getItem("user"));
  const [users, setUsers] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [selectedUser, setSelectedUser] = React.useState(null);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(`${apiUrl}/auth/getUsers`, {
          headers: {
            Authorization: `Bearer ${me.token}`,
          },
        });
        setUsers(response.data.users);

        setIsLoading(false);
      } catch (error) {
        console.error(error);
      }
    };
    fetchUsers();
  }, []); // Depend on location to trigger effect on URL change

  return (
    <PageStructure>
      <h1>Users Transactions</h1>

      {!isLoading && 
        <>
        <Select
        options={users.map((user) => ({
            value: user._id,
            label: user.name,
        }))}
        onChange={(selectedOption) => {
            setSelectedUser(selectedOption);
        }}
        />

      {selectedUser && (
          <UserTransactionsComponent
          key={selectedUser.value}
          userId={selectedUser.value}
          />
        )}
        </>
        }

        {isLoading &&  <Loader />}
    </PageStructure>
  );
};

export default UsersTransactions;
