import React from "react";
import { useUser } from "../../contexts/UserContext";

import "./AccountingSettings.css";
import {
  AddTicketButton,
  Sidebar,
  TaskManager,
  TicketTable,
  AddCategoryOption,
  AddPriorityOption,
  AddStatusOption,
  UpdateCategoryOption,
  UpdatePriorityOption,
  UpdateStatusOption,
  AddTransactionReason,
  UpdateTransactionReason,
  AddTransactionStatus,
  UpdateTransactionStatus,
  AddPaymentMethod,
  UpdatePaymentMethod,
  AddCompanyForm,
  EditCompany,
} from "../../components";
import { PageStructure } from "..";
import { Edit } from "@mui/icons-material";

const AccountingSettings = () => {
  return (
    <PageStructure>
      <h1>Accounting Settings</h1>

      <hr />
      <h3 style={{ textAlign: "center" }}>Transaction Reasons Settings</h3>
      <AddTransactionReason />
      <UpdateTransactionReason />
      <hr />
      <h3 style={{ textAlign: "center" }}>Transactions Status Settings</h3>
      <AddTransactionStatus />
      {/* <UpdateCategoryOption /> */}
      <UpdateTransactionStatus />

      <hr />
      <h3 style={{ textAlign: "center" }}>Method of Payment Settings</h3>
      <AddPaymentMethod />
      <UpdatePaymentMethod />
      <hr />
      <h3 style={{ textAlign: "center" }}>Company Settings</h3>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <h4
          style={{
            alignSelf: "flex-start",
            marginBottom: "10px",
            fontWeight: "600",
          }}
        >
          Add Company
        </h4>

        <AddCompanyForm />
      </div>
      <EditCompany />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          height: "120px",
        }}
      />
    </PageStructure>
  );
};

export default AccountingSettings;
